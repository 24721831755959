import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Snackbar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useGetRentalTenants } from "services/hooks/rentals";
import { setRefreshCount } from "state";
import ExpensesTable from "components/Tables/ExpensesTable";

export default function LandlordExpenses() {
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const [open, setOpen] = React.useState(false);
  const [dialogType, setDialogType] = React.useState(""); // Track dialog type (Edit, Send Message, Delete, Add Expense)
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [formData, setFormData] = React.useState({
    expenseName: "",
    date: "",
    amount: "",
  });
  const [messageText, setMessageText] = React.useState(""); // Message text for sending message
  const [openSnack, setOpenSnack] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [severity, setSeverity] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [itemsPerPage] = React.useState(10); // Removed setter for itemsPerPage
  const [filterText, setFilterText] = React.useState(""); // Filter text for filtering data
  const [filterType, setFilterType] = React.useState("expenseName"); // Default filter type

  const { rentalTenants = [] } = useGetRentalTenants(); // Default to empty array if undefined

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleMenuClick = (action, item) => {
    setSelectedItem(item);
    setDialogType(action);
    if (action === "Edit") {
      setFormData({
        expenseName: item.expenseName || "",
        date: item.date || "",
        amount: item.amount || "",
      });
    } else if (action === "Send Message") {
      setMessageText(""); // Reset message text
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItem(null);
    setDialogType("");
    setFormData({
      expenseName: "",
      date: "",
      amount: "",
    });
    setMessageText(""); // Clear message text
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMessageChange = (event) => {
    setMessageText(event.target.value);
  };

  const handleFilterTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleSubmit = () => {
    if (dialogType === "Edit") {
      // Handle edit logic
      axios
        .put(`/api/expenses/${selectedItem.id}`, formData)
        .then(() => {
          setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
          setSeverity("success");
          setMessage("Expense updated successfully");
          dispatch(setRefreshCount());
        })
        .catch((error) => {
          console.error("Error updating expense:", error);
          setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
          setSeverity("error");
          setMessage("Error updating expense");
        });
    } else if (dialogType === "Send Message") {
      // Handle send message logic
      console.log("Sending Message to", selectedItem, "Message:", messageText);
      setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
      setSeverity("success");
      setMessage("Message sent successfully");
    } else if (dialogType === "Delete") {
      // Handle delete logic
      if (window.confirm("Are you sure you want to delete this expense?")) {
        axios
          .delete(`/api/expenses/${selectedItem.id}`)
          .then(() => {
            setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
            setSeverity("success");
            setMessage("Expense deleted successfully");
            dispatch(setRefreshCount());
          })
          .catch((error) => {
            console.error("Error deleting expense:", error);
            setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
            setSeverity("error");
            setMessage("Error deleting expense");
          });
      }
    } else if (dialogType === "Add Expense") {
      // Handle add expense logic
      axios
        .post(`/api/expenses`, formData)
        .then(() => {
          setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
          setSeverity("success");
          setMessage("Expense added successfully");
          dispatch(setRefreshCount());
        })
        .catch((error) => {
          console.error("Error adding expense:", error);
          setOpenSnack({ open: true, vertical: "top", horizontal: "center" });
          setSeverity("error");
          setMessage("Error adding expense");
        });
    }
    handleClose();
  };

  // Apply filter if filterText is not empty
  const filteredData = rentalTenants.filter((item) => {
    const valueToFilter = item[filterType] || "";
    return valueToFilter.toLowerCase().includes(filterText.toLowerCase());
  });

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        <Box sx={{ flexGrow: 1, paddingLeft: "80px", padding: "80px" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 5, fontSize: 25 }} // Increased margin bottom
          >
            Landlord's Expenses
          </Typography>
          <Box sx={{ mb: 2, display: "flex", gap: 2, alignItems: "center" }}>
            <FormControl variant="outlined" size="small" sx={{ flexGrow: 1 }}>
              <InputLabel>Filter By</InputLabel>
              <Select
                value={filterType}
                onChange={handleFilterTypeChange}
                label="Filter By"
                sx={{ width: 300, fontSize: 14 }} // Reduced width and adjusted font size
              >
                <MenuItem value="expenseName">Expense Name</MenuItem>
                <MenuItem value="date">Date</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDialogType("Add Expense");
                setOpen(true);
              }}
            >
              Add Expense
            </Button>
          </Box>
          <ExpensesTable
            data={paginatedData}
            onMenuClick={handleMenuClick}
            onPageChange={handlePageChange}
            page={currentPage}
            rowsPerPage={itemsPerPage}
          />
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
              {dialogType === "Edit" && "Edit Expense"}
              {dialogType === "Send Message" && "Send Message"}
              {dialogType === "Delete" && "Delete Expense"}
              {dialogType === "Add Expense" && "Add Expense"}
            </DialogTitle>
            <DialogContent>
              {(dialogType === "Edit" || dialogType === "Add Expense") && (
                <>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Expense Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="expenseName"
                    value={formData.expenseName}
                    onChange={handleFormChange}
                  />
                  <TextField
                    margin="dense"
                    label="Date"
                    type="date"
                    fullWidth
                    variant="outlined"
                    name="date"
                    value={formData.date}
                    onChange={handleFormChange}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    margin="dense"
                    label="Amount"
                    type="number"
                    fullWidth
                    variant="outlined"
                    name="amount"
                    value={formData.amount}
                    onChange={handleFormChange}
                  />
                </>
              )}
              {dialogType === "Send Message" && (
                <TextField
                  autoFocus
                  margin="dense"
                  label="Message"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={4}
                  value={messageText}
                  onChange={handleMessageChange}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit} color="primary">
                {dialogType === "Edit" && "Save"}
                {dialogType === "Send Message" && "Send"}
                {dialogType === "Delete" && "Delete"}
                {dialogType === "Add Expense" && "Add"}
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={openSnack.open}
            autoHideDuration={6000}
            onClose={() =>
              setOpenSnack({
                ...openSnack,
                open: false,
              })
            }
            message={message}
            severity={severity}
          />
        </Box>
      </Box>
    </Box>
  );
}
