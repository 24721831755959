import React from "react";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentTab } from "state";
import CustomTab from "components/Tabs/Tab";
import Payments from "scenes/Payments";
import Invoices from "scenes/Invoices/Invoices";
import LandlordPayments from "scenes/Payments/landlordPayments";
import Expenses from "scenes/Expenses/Expenses";



const FinancialsTab = () => {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['financialsTab']) );
    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setCurrentTab({ name: "financialsTab", tab: newValue }));
    };

    const tabComponents = [
      { Component: <Invoices />, label: "INVOICES", index: 0 },
      { Component: <Payments />, label: "PAYMENTS", index: 1 },
      { Component: <LandlordPayments />, label: "PAYOUTS", index: 2 },
      { Component: <Expenses />, label: "EXPENSES", index: 3 },
    ]; 


    return (
        <Box>
            <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange}/> 
        </Box>
    )

}

export default FinancialsTab;