import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
	labels: ["So Far Paid", "Rent Due"],
	datasets: [
		{
			label: "Rent Payments",
			data: [200000, 350000],
			backgroundColor: [
				"rgba(255, 99, 132, 0.2)",
				"rgba(98, 245, 162, 0.6)",
			],
			borderColor: [
				"rgba(255, 99, 132, 1)",
				"rgba(54, 162, 235, 1)",
			],
			borderWidth: 1,
		},
	],
};

const options = {
	responsive: true, // Make the chart responsive
	maintainAspectRatio: false, // Disable maintaining the aspect ratio
	plugins: {
		legend: {
			position: "top",
		},
		title: {
			display: false,
			text: "Rent Payments",
		},
	},
};

export default function DoughnutChart() {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box sx={{ width: { xs: "250px", sm: "300px" }, height: { xs: "250px", sm: "300px" } }}>
				<Doughnut data={data} options={options} />
			</Box>
		</Box>
	);
}
