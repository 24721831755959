import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Grid, TextField } from "@mui/material";

export default function TicketUpdateForm(props) {
	return (
		<Grid sx={{ padding:"0.5rem" }} container component="form" noValidate autoComplete="off" spacing={2}>
			<Grid item xs={12} sm={6}>
					<TextField select fullWidth size="small" labelId="rental-type" id="rental-type-id" value={props.rental} label="Current Status" onChange={props.handleChangeRental}>
						<MenuItem value="1">IN PROGRESS</MenuItem>
						<MenuItem value="2">FIXED</MenuItem>
					</TextField>
			</Grid>
			<Grid item xs={12} sm={6}>
					<TextField select fullWidth size="small" labelId="rental-type" id="rental-type-id" value={props.rental} label="Severity" onChange={props.handleChangeRental}>
						<MenuItem value="1">MINOR</MenuItem>
						<MenuItem value="2">MEDIUM</MenuItem>
						<MenuItem value="2">MAJOR</MenuItem>
						<MenuItem value="2">IMPORTANT</MenuItem>
					</TextField>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextField size="small" fullWidth label="Ticket Name" defaultValue={props.details.title} />
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextField size="large" fullWidth label="Ticket Description" defaultValue={props.details.description} />
			</Grid>
		</Grid>
	);
}
