import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TicketView from 'components/ModalContent/ViewDetails/TicketView';
import TicketUpdate from 'components/ModalContent/UpdateItem/TicketUpdateModal';
import TicketDelete from 'components/ModalContent/DeleteItem/TicketDelete';


export default function InvoicesPopover({popperId, openPopper, anchorEl, handleClosePopOver, handleClickOpen, details, handleModalView}) {
	const handleAction = (modalview) => {
		handleModalView(modalview);
		handleClickOpen();
		handleClosePopOver();
	};
	return (
		<div>
			<Popover
				id={popperId}
				open={openPopper}
				anchorEl={anchorEl}
				onClose={handleClosePopOver}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Stack
					sx={{
						padding: "10px",
						alignContent: "left",
					}}
				>
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("view")}>
						View Invoice
					</Button>
					{/* <Divider />
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("edit")}>
						Edit Invoice
					</Button> */}
					<Divider />
					<Button sx={{ color: "red", padding: "10px" }} onClick={() => handleAction("delete")}>
						Delete Invoice
					</Button>
					<Divider />
				</Stack>
			</Popover>
		</div>
	);
}