import * as React from 'react';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Grid } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';

export default function TenantsForm({handleChangeTenantNumber, rentals, handleChangeRental, errors,  isLoadedUnits, units, unit, tenant, rental, loadedTenant, isExistingTenant, errorTenant,loadingTenantsError, handleChangeTenantDetails}) {
  return (
    <Grid
      container
      component="form"
      sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h6" component="div">Enter the Tenants Phone number starting with 256, for example 256770123456</Typography>
      <Grid item xs={6} spacing={2} sx={{ marginTop: 2 }}>
        <TextField
          required
          id="outlined-required"
          label="Tenants Phone Number"
          onChange={handleChangeTenantNumber}
          fullWidth={true}
          name="phone_number"
          margin="dense"
          type='number'
          autoFocus
          error={Boolean(errors.phone_number)}
          helperText={errors.phone_number}
        />
      </Grid>
      {loadingTenantsError && (
        <Typography variant="h6" component="div" sx={{ color: "red" }}>
          {loadingTenantsError}
        </Typography>
      )}

      {loadedTenant ? (
        <Grid item xs={12} spacing={2} sx={{ marginTop: 2 }}>
          <Card variant="outlined" sx={{ minWidth: 100 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Tenant Name: {tenant.first_name} {tenant.last_name}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Tenant Contact: +256{tenant.phone_number}
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Tenant Number: {tenant.tenant_number}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <></>
      )}

      {!isExistingTenant && (
        <Grid item xs={12} spacing={2} sx={{ marginTop: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <TextField
              required
              id="outlined-required"
              label="Tenants First Name"
              onChange={handleChangeTenantDetails}
              fullWidth={true}
              name="first_name"
              error={Boolean(errors.first_name)}
              helperText={errors.first_name}
              margin="dense"
              autoFocus
            />

            <TextField
              required
              id="outlined-required"
              label="Tenants Last Name"
              onChange={handleChangeTenantDetails}
              fullWidth={true}
              name="last_name"
              error={Boolean(errors.last_name)}
              helperText={errors.last_name}
              margin="dense"
            />
          </Box>
        </Grid>
      )}

      <Grid item xs={12} spacing={2} sx={{ marginTop: 2 }}>
        {loadedTenant || !isExistingTenant ? (
          <TextField
            labelId="demo-simple-select-label"
            id="rental-select"
            value={rental}
            label="Select Rental"
            select
            sx={{ width: "40%" }}
            error={Boolean(errors.unit_id)}
            onChange={handleChangeRental}
          >
            {rentals.map((value, index) => {
              return (
                <MenuItem key={index} value={value.id}>
                  {value.rental_name} - {value.location}
                </MenuItem>
              );
            })}
          </TextField>
        ) : null}

        {isLoadedUnits ? (
          <>
            <TextField
              labelId="demo-simple-select-label"
              id="unit-select"
              // value={unit}
              label="Select Unit"
              name="unit_id"
              select
              error={Boolean(errors.unit_id)}
              helperText={errors.unit_id}
              onChange={handleChangeTenantDetails}
            >
              {units.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.id}>
                    {value.related_rental_unit.unit_name} -{" "}
                    {value.related_rental_unit.unit_type}
                  </MenuItem>
                );
              })}
            </TextField>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Tooltip title="The amount that must be paid by a new tenant including the security deposit if any and initial months of occupancy of the unit">
                <TextField
                  required
                  id="rent-deposit-amount"
                  label="Rent Deposit Amount"
                  defaultValue={0}
                  onChange={handleChangeTenantDetails}
                  error={Boolean(errors.rent_deposit_amount)}
                  type="number"
                  fullWidth={true}
                  name="rent_deposit_amount"
                  helperText={errors.rent_deposit_amount}
                  margin="dense"
                  autoFocus
                />
              </Tooltip>

              <Tooltip title="The months covered by the deposit amount">
                <TextField
                  required
                  id="months-covered"
                  label="Months Covered by Deposit"
                  onChange={handleChangeTenantDetails}
                  fullWidth={true}
                  defaultValue={0}
                  name="months_covered"
                  type="number"
                  inputProps={{
                    min: 1,
                  }}
                  margin="dense"
                  error={Boolean(errors.months_covered)}
                  helperText={errors.months_covered}
                  autoFocus
                />
              </Tooltip>
              
              <Tooltip title="The date that the tenant will start occupying the unit. This will help us know when next they have to make a payment">
                <TextField
                  label="Occupancy Start Date"
                  type="date"
                  defaultValue={moment().format("YYYY-MM-DD")}
                  name="next_payment_date"
                  onChange={handleChangeTenantDetails}
                  error={Boolean(errors.next_payment_date)}
                  helperText={errors.next_payment_date}
                />
              </Tooltip>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}