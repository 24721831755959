import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Autocomplete, Box} from "@mui/material";

export default function EditInvoiceForm({handleChange, properties, newInvoice, errors}) {

	return (
    <>
      <Grid
        container
        component="form"
        noValidate
        autoComplete="off"
        spacing={2}
        sx={{ marginTop: "1px" }}
      >
        <Grid item xs={12}>
          <Autocomplete
            onChange={(e, value)=>handleChange({target:{ value: value.id, name:"related_occupancy" }})}
            label="Select Rental"
            fullWidth
            name="related_occupancy"
            options={properties}
            defaultValue={newInvoice.related_occupancy}
            getOptionLabel={(option) =>
              `${option.related_tenant.first_name} ${option.related_tenant.last_name}-${option.related_rental_unit.related_rental.rental_name}`
            }
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, properties) => (
              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props} key={properties.id}>
                {properties.related_tenant.first_name} {properties.related_tenant.last_name}-{properties.related_rental_unit.related_rental.rental_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{...params.inputProps,}}
                label="Type Tenant or Rental Unit Name"
                error={errors.related_occupancy}
                helperText={errors.related_occupancy}
                value={newInvoice.related_occupancy ? newInvoice.related_occupancy ?? null : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size="small"
            multiline
            rows={4}
            fullWidth
            name="invoice_description"
            label="Invoice Description"
            onChange={handleChange}
            value={newInvoice.invoice_description}
            error={Boolean(errors.invoice_description)}
            helperText={errors.invoice_description}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="number"
            label="Invoice Amount"
            name="invoice_amount"
            onChange={handleChange}
            value={newInvoice.invoice_amount}
            error={Boolean(errors.invoice_amount)}
            helperText={errors.invoice_amount}
          />
        </Grid>
      </Grid>
    </>
  );
}
