import React, {useState, useEffect} from "react";
import axios from "axios";
import { baseURL } from "services/API";
import UnitsTable from "../../components/Tables/UnitsTable";
import DummyTable from "components/Tables/DummyTable";
import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import { useRentalUnits } from "services/hooks/rentals";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setPropertyId } from "state";
import ProgressScale from "components/ProgressScale";
import UnitsPopover from "components/PopOvers/UnitsPopover";
import FormDialog from "components/modal";

const Units = () => {
	const navigate = useNavigate();
	const property_id = useSelector((state) => state.property_id);
	const [activeUnit, setActiveUnit] = useState("")
	const [modal, setModal] = React.useState("");

	const [anchorEl, setAnchorEl] = useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;
	const [open, setOpen] = React.useState(false);
	const { rentalUnits, isLoadingRentalUnits, error } = useRentalUnits(property_id);
	const [propertyDetails, setPropertyDetails] = useState({})
	const [loadingPropertyDetails, setLoadingPropertyDetails] = useState(false)

	const fetchDetails = async () => {
		try {
			setLoadingPropertyDetails(true);
			const response = await axios.get(`${baseURL}/landlords/rentals/details?rental_id=${property_id}`);
			setPropertyDetails(response.data.data);
			setLoadingPropertyDetails(false);
		} catch (e) {
			setPropertyDetails(true);
			setLoadingPropertyDetails(false);
		}
	}
	
	useEffect(() => {
		fetchDetails()
	}, [])

	const handleClickOpen = () => {
		setOpen(true);
	}

	const handleClickPopOver = (event, row) => {
		setAnchorEl(event.currentTarget);
		setActiveUnit(row);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	const handleClose = () => {
		setModal("");
	};

	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12} spacing={2}>
					{isLoadingRentalUnits && <ProgressScale />}
					<div style={{ display: "block" }}>
						<Typography variant="h4" fontWeight={"bold"}>Units for Property {propertyDetails.rental_name}</Typography>
					</div>
					<Grid container>
						<Grid container>
							<Grid container spacing={2}>
								<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
									{/* <BasicSelect value={unitName} label={"Properties"} data={rentalUnits} /> */}
								</Grid>
								<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
									<Button className="add-button" variant="contained" onClick={()=>navigate("/units/create")} endIcon={<AddIcon />}>
										Add Property Unit
									</Button>
								</Grid>
								<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
									{/* <BasicSelect value={unitName} label={"Properties"} data={rentalUnits} /> */}
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{ marginTop: 3 }}>
								{isLoadingRentalUnits ? 
									<DummyTable /> : 
									<UnitsTable rows={rentalUnits} isLoadingRentalUnits={isLoadingRentalUnits} handleClickPopOver={handleClickPopOver}
									id={popperId}/>
								}
							</Grid>
						</Grid>

						<UnitsPopover
							popperId={popperId}
							openPopper={openPopper}
							anchorEl={anchorEl}
							handleClosePopOver={handleClosePopOver}
							handleClickOpen={handleClickOpen}
							navigate={navigate}
							activeUnit={activeUnit}
							handleModalView={handleModalView}
						/>
					</Grid>
				</Grid>
			</Grid>
			{modal === "delete" ? (
				<FormDialog
					title="Delete Unit"
					enableActions={true}
					open={open}
					content="Are you sure you want to delete this Unit?"
					handleClose={handleClose}
					buttonText="Delete Unit"
				/>
			) :  null}
		</Box>
	);
};

export default Units;
