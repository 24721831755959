import * as React from "react";
import { Grid, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import BasicSelect from "components/select";
import { formatDate } from "utilities/date";
import { useGetLandlordInvoicesData, useGetRentalTenants, useRentals } from "services/hooks/rentals";
import ProgressScale from "components/ProgressScale";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import CreateInvoiceForm from "components/Forms/InvoiceForms/CreateInvoiceForm";
import LandlordInvoicesTable from "components/Tables/LandlordInvoicesTable";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "services/API";
import InvoicesPopover from "components/PopOvers/InvoicesPopover";
import EditInvoiceForm from "components/Forms/InvoiceForms/EditInvoiceForm";
import InvoiceView from "components/views/invoiceView";

export default function Invoices() {
	const [rental, setRental] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [details, setDetails] = React.useState({});
	const landlord = useSelector((state)=>state.landlord);
	const [modal, setModal] = React.useState("");
	const { invoicesData, isLoadingInvoicesData, errorLoadingInvoicesData, refetch } = useGetLandlordInvoicesData();
	const [newInvoice, setNewInvoice] = React.useState({
		related_landlord: landlord,
		related_occupancy: 0,
		invoice_amount: "",
		invoice_description: "",
		payment_terms:"",
		add_vat: false
	});
  	const [errors, setErrors] = React.useState({});
	const [isSubmittingData, setIsSubmittingData] = React.useState(false);
	const { rentals } = useRentals();
	const {rentalTenants} = useGetRentalTenants()
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	const [successMessage, setSuccessMessage] = React.useState("");
	const [errorMessage, setErrorMessage] = React.useState("");
	const [openSnack, setOpenSnack] = React.useState(false);

  	const handleChange = (event) => {
      const { name, value } = event.target;
      setNewInvoice((prev) => ({ ...prev, [name]: value }));
      if (!toString(value).trim()) {
        setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    };

	const validateForm = () => {
		const errors = {};

		if (newInvoice.related_occupancy===0) errors.related_occupancy = "Please select a property";
		if (!newInvoice.invoice_description.trim()) errors.invoice_description = "Invoice Desription is required";
		if (!newInvoice.invoice_amount.trim()) errors.invoice_amount = "Invoice Amount is required";

		setErrors(errors);
		return Object.keys(errors).length === 0;
	};

	const handleDownload =()=>{
		
	}

	const handleSubmitInvoice = async () => {

		if (!validateForm()) {
			setOpenSnack({
				open: true,
				message: "Please fill all fields",
				severity: "error",
			});
			return;
		}
		try {
			console.log(newInvoice)
			const response = await axios.post(`${baseURL}/landlords/rentals/invoices`, newInvoice);
			setSuccessMessage(`Invoice added successfully`)
		
		} catch (error) {
			console.error(error);
			setErrorMessage(error.response?.data?.error)
		} finally {
			setNewInvoice({
				related_landlord: landlord,
				related_occupancy: 0,
				invoice_amount: "",
				invoice_description: "",
				payment_terms:"",
				add_vat: false
			});
			refetch();
			setIsSubmittingData(false);
			handleClose();
		}
	};

	const handleSubmitEditInvoice = async () => {

		if (!validateForm()) {
			setOpenSnack({
				open: true,
				message: "Please fill all fields",
				severity: "error",
			});
			return;
		}
		try {
			console.log(newInvoice)
			const response = await axios.post(`${baseURL}/landlords/rentals/invoices`, newInvoice);
			setSuccessMessage(`Invoice editted successfully`)
		
		} catch (error) {
			console.error(error);
			setErrorMessage(error.response?.data?.error)
		} finally {
			setNewInvoice({
				related_landlord: landlord,
				related_occupancy: 0,
				invoice_amount: "",
				invoice_description: "",
				payment_terms:"",
				add_vat: false
			});
			refetch();
			setIsSubmittingData(false);
			handleClose();
		}
	};

	const handleSubmitDeleteInvoice = () => {

	}

	const handleSelectChange = (event) => {
		setRental(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleModalView = (modalView) => {
		if (modalView === 'edit') {
			setNewInvoice(details);
		} 
		setModal(modalView);
	};

	const handleClose = () => {
		setOpen(false);
		setNewInvoice({
			related_landlord: landlord,
			related_occupancy: 0,
			invoice_amount: "",
			invoice_description: "",
			payment_terms:"",
			add_vat: false
		});
		setErrors({});
	};

	const handleClickPopOver = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	return (
		<Box m="10px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingInvoicesData && <ProgressScale />}
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<Typography variant="h2" fontWeight={"bold"}>Invoices</Typography>
					</div>
					{
						successMessage && 
						<p sx={{color: "green", fontSize: "14px"}}>{successMessage}</p>
					}
					{errorMessage && (
						<p style={{ color: "red", fontSize: "14px" }}>{errorMessage}</p>
					)}
					<Grid container spacing={2}>
						<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
						</Grid>
                        <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Month"} data={rentals} />
						</Grid>
                        <Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
                            <Button
								className="add-button"
								variant="contained"
								endIcon={<AddIcon />}
								onClick={()=>handleClickOpen()}
                            >
                                Create Invoice
                            </Button>
                        </Grid>
						<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
							{/* <Button className="add-button" variant="contained" onClick={handleClickOpen} endIcon={<AddIcon />}>
											Add Ticket
										</Button> */}
						</Grid>
						<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
							<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter Rental"} data={rentals} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingInvoicesData ? (
								<DummyTable />
							) : (
								<LandlordInvoicesTable
									rows={invoicesData}
									handleClickPopOver={handleClickPopOver}
									id={popperId}
									formatDate={formatDate}
									setDetails={setDetails}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<InvoicesPopover
				popperId={popperId}
				openPopper={openPopper}
				anchorEl={anchorEl}
				handleClosePopOver={handleClosePopOver}
				handleClickOpen={handleClickOpen}
				details={details}
				handleModalView={handleModalView}
			/>
			{modal === "view" ? (
				<FormDialog
					title={`View Invoice`}
					enableActions={false}
					open={open}
					handleClose={handleClose}
					buttonText="Download Invoice"
					content={
						<InvoiceView invoiceDetail={details}/>
					}
					handleSubmit={handleDownload}
					isSubmittingData={isSubmittingData}
				/>
			) :modal === 'edit' ? (
				<FormDialog
					title="Edit Invoice"
					enableActions={true}
					content={<EditInvoiceForm 
								properties={rentalTenants}
								handleChange={handleChange}
								errors={errors}
								newInvoice={newInvoice}
							/>}
					open={open}
					handleClose={handleClose}
					buttonText="Edit Invoice"
					handleSubmit={handleSubmitEditInvoice}
					isSubmittingData={isSubmittingData}
				/>
			): modal === "delete" ? (
						<FormDialog
							title="Delete Invoice"
							enableActions={true}
							open={open}
							content="Are you sure you want to delete this Invoice?"
							handleClose={handleClose}
							buttonText="Delete Invoice"
							handleSubmit={handleSubmitDeleteInvoice}
							isSubmittingData={isSubmittingData}
						/>
			):(
				<FormDialog
					open={open}
					handleClose={handleClose}
					title={"Add A New Invoice"}
					enableActions={true}
					buttonText="Create Invoice"
					buttonClass={"add-button"}
					content={
						<CreateInvoiceForm
							properties={rentalTenants}
							handleChange={handleChange}
							errors={errors}
							newInvoice={newInvoice}
						/>
					}
					handleSubmit={handleSubmitInvoice}
					isSubmittingData={isSubmittingData}
				/>
			)}
			
		</Box>
	);
}
