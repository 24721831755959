import React from "react";
import {Box,Typography,Paper, TableHead,} from "@mui/material";
import { FlexBetween, FlexColCenter, FlexColLeft, FlexColRight, FlexEnd, FlexRowCenter, FlexStart } from "components/FlexBox/FlexBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {StyledTableCell,StyledTableRow,} from "components/Tables/TableCellandRow";
import { currencyFormatter, withoutSymbolFormatter } from "utilities/currencyFormatter"; 
import moment from "moment";


const ExpenseView = ({expenseDetail}) => {

  const expense_description = expenseDetail.description;
  const expense_totals = expenseDetail.amount;
  const created_by = expenseDetail.added_by;
  const created_by_location = expenseDetail.related_landlord_rental
  const expense_category = expenseDetail.related_expense_detail;
  

  return (
    <Box>
        
        <Box sx={{display: "flex", width:"100%", justifyContent:"center" }}>
          <Paper sx={{ p: "10px", display: "flex", flexDirection:"column", margin:"10px 0", width:"210mm", }}>
            <FlexBetween sx={{ marginBottom:"15px" }}>
              <Typography variant="h2" sx={{ justifyContent:"flex-start", padding:"6px" }}>Expense Record</Typography>
              <img src="../images/rent-beta-logo_dark.png" height="40px" width="200" />
            </FlexBetween>

            <FlexBetween sx={{ marginBottom:"15px" }}>
              <FlexColLeft>
                <FlexBetween>
                  <Typography fontWeight="bold">RECORD#</Typography>
                  <Typography>{expenseDetail.id}</Typography>
                </FlexBetween>
              </FlexColLeft>
              <FlexColRight sx={{ width:"40%", marginRight:"5px" }}>
                <FlexBetween>
                  <Typography fontWeight="bold">DATE RECORDED</Typography>
                  <Typography>{moment(expenseDetail.created_on).format('DD/MM/YYYY')}</Typography>
                </FlexBetween>
              </FlexColRight>
            </FlexBetween>
            
            <FlexStart sx={{ marginBottom:"15px" }}>
              <FlexColLeft>
                <Typography fontWeight="bold">{created_by.first_name+' '+created_by.last_name}</Typography>
                <Typography>{created_by_location.rental_name}</Typography>
                <Typography>{created_by_location.location_county+', '+created_by_location.location}</Typography>
              </FlexColLeft>
            </FlexStart>

            
            <TableContainer sx={{ maxHeight: "100vh",  marginBottom:"15px"  }}>
              <Table stickyHeader aria-label="sticky table" maxHeight="100vh">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell  style={{ backgroundColor: "white",padding: "6px 6px",margin:"5px",borderBottom: "2px solid",}}>
                      <FlexColCenter><Typography variant="h5">EXPENSE DESCRIPTION</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell  style={{ backgroundColor: "white",padding: "6px 6px",margin:"5px",borderBottom: "2px solid",}}>
                      <FlexColCenter><Typography variant="h5">CATEGORY</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell  style={{ backgroundColor: "white",padding: "6px 6px",margin:"5px",borderBottom: "2px solid",}}>
                      <FlexColCenter><Typography variant="h5">AMOUNT</Typography></FlexColCenter>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell style={{padding: "5px 5px",border: "1px",}}>
                      <FlexColCenter><Typography variant="h6">{expense_description}</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "5px 5px",border: "1px",}}>
                      <FlexColCenter><Typography variant="h6">{expense_category.expense_detail+ '-' + expense_category.explanation}</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "5px 5px",border: "1px",}}>
                      <FlexColCenter><Typography variant="h6">{withoutSymbolFormatter(parseFloat(expense_totals))}</Typography></FlexColCenter>
                    </StyledTableCell>
                  </StyledTableRow>  
                  <StyledTableRow style={{  }}>
                    <StyledTableCell style={{padding: "5px 5px",border: "1px", borderBottom:"2px solid"}}>
                      <FlexColCenter><Typography variant="h5"></Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "5px 5px",border: "1px", borderBottom:"2px solid"}}>
                      <FlexColCenter><Typography variant="h5" fontWeight="bold">TOTAL EXPENSES</Typography></FlexColCenter>
                    </StyledTableCell>
                    <StyledTableCell style={{padding: "5px 5px",border: "1px", borderBottom:"2px solid"}}>
                      <FlexColCenter><Typography variant="h5" fontWeight="bold">{currencyFormatter(parseFloat(expense_totals))}</Typography></FlexColCenter>
                    </StyledTableCell>
                  </StyledTableRow>            
                </TableBody>
              </Table>
            </TableContainer>
            <hr/>
            <div>
              <Typography  fontWeight="bold">SUBMITTED BY............................................................</Typography>
            </div>
        </Paper>
        </Box>
      </Box>
  );
};

export default ExpenseView;
