import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import PaidIcon from '@mui/icons-material/Paid';

export default function TopCard({ cardDescription, cardMoney, sign }) {
	return (
		<Box sx={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			marginY: "10px",
            border: "1px solid grey",
            paddingY: "25px",
            paddingX: "10px",
            borderRadius: "12px",
		}}>
			<Box>
				<Typography sx={{ fontWeight: 400, marginY: "10px" }} variant="h5" component="div">
					{cardDescription}
				</Typography>
				<Typography sx={{ fontWeight: 800 }} variant="h3" component="div">
					{cardMoney}
				</Typography>
			</Box>
			<PaidIcon color="success" sx={{fontSize: 40}}/>
		</Box>
	);
}