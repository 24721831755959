import * as React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import BottomCard from "components/Cards/BottomCard";
import TopCard from "components/Cards/TopCard";
import ProgressScale from "components/ProgressScale";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import ReportTypography from "components/Typrographies/ReportTypography";

export default function RentalTax() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;


	return (
            <>
            <Typography sx={{ fontWeight: 500 }} variant="h3" component="div">
                Revenue report for the year 2024
            </Typography>
            <Grid item md={12} sm={12} sx={{
                display: "flex",
                flexDirection: "row"
            }}>
                <Grid md={6} sm={12} lg={6} sx={{
                    border: "1px solid grey",
                    padding: "15px",
                    marginY: "10px",
                    marginRight: "12px",
                    borderRadius: "10px"
                }}>
                    <Typography sx={{ fontWeight: 350 }} variant="h4" component="div">
                        Revenue for the entire year
                    </Typography>
                    <Divider />
                    <Grid >
                        <TopCard cardDescription={"Total Revenue"} cardMoney={"UGX 3,000,000"} />
                    </Grid>

                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row"
                        }}>
                        <Grid md={4}>
                            <BottomCard cardTitle={"January, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"February, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"March, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row"
                        }}>
                        <Grid md={4}>
                            <BottomCard cardTitle={"April, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"May, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"June, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                    </Grid>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "row"
                        }}>
                        <Grid md={4}>
                            <BottomCard cardTitle={"July, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"August, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                        <Grid md={4}>
                            <BottomCard cardTitle={"September, 2024"} cardMoney={"UGX 150,000"} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid md={6} sm={12} lg={6} sx={{
                    marginLeft: "12px"
                }}>
                    <Typography sx={{ fontWeight: 350 }} variant="h4" component="div">
                        Rental tax estimates for the year 2024 (To be paid once a year)
                    </Typography>
                    <Divider />
                    <Grid >
                        <TopCard cardDescription={"For Sole Proprietorships"} cardMoney={"UGX 300,000"}/>
                    </Grid>
                    <ReportTypography title={"For Sole proprietorships,"} />
                    <ReportTypography title={"1. Get total (3,000,000), Subtract 2,820,000"} />
                    <ReportTypography title={"2. Taxable income on your rentals will be 75,000"} />
                    <ReportTypography title={"3. Get 12% of the taxable income from your rentals"} />
                    <Grid >
                        <TopCard cardDescription={"For Parternships"} cardMoney={"UGX 560,000"} />
                    </Grid>
                    <ReportTypography title={"For Sole proprietorships,"} />
                    <ReportTypography title={"1. Get total (3,000,000), Subtract 2,820,000"} />
                    <ReportTypography title={"2. Taxable income on your rentals will be 75,000"} />
                    <ReportTypography title={"3. Get 12% of the taxable income from your rentals"} />
                    <Divider />
                    <Grid >
                        <TopCard cardDescription={"For Companies"} cardMoney={"UGX 650,000"} />
                    </Grid>
                    <ReportTypography title={"For Sole proprietorships,"} />
                    <ReportTypography title={"1. Get total (3,000,000), Subtract 2,820,000"} />
                    <ReportTypography title={"2. Taxable income on your rentals will be 75,000"} />
                    <ReportTypography title={"3. Get 12% of the taxable income from your rentals"} />
                </Grid>
            </Grid>
        </>
	);
}
