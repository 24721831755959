import { useState } from "react";
import { Box, IconButton, InputBase, Typography, Select, MenuItem, FormControl, useTheme, useMediaQuery, Badge, Menu, Tooltip, Grid } from "@mui/material";
import { Search, Message, DarkMode, LightMode, Notifications, Help, Menu as MenuIcon, Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import { tokens } from "theme";
import { FaSignOutAlt } from "react-icons/fa";
import NavMenu from "components/NavMenu";
import NavAvatar from "components/Avatars/NavAvatar";

const Navbar = () => {
	const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user);
	const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const neutralLight = colors.primary[10];
	const dark = colors.grey[5];
	const white = colors.primary[10];
	const universalMedium = colors.primary[10];
	const fullName = `${user.first_name} ${user.last_name}`;

	const logout = () => {
		dispatch(setLogout())
		navigate("/login")
	};

	const notiificationList = [];
	const [openNotifications, setOpenNotifications] = useState(false);
	const [anchorE1, setAnchorE1] = useState(null);
	const badgeContent = notiificationList.length;
	const handleClickOpen = (event) => {
		setAnchorE1(event.currentTarget);
		setOpenNotifications(true);
	};

	const handleClose = () => {
		setOpenNotifications(false);
	};


	return (
		<FlexBetween padding="1rem 10%" backgroundColor={dark} sx={{ borderBottom: "1px solid #dfdede;", width:"100%" }}>
			<FlexBetween gap="1.75rem">
				<img src="/images/rent-beta-logo_dark.png" className="navlogo" alt="rent beta logo" />
			</FlexBetween>

			{/* DESKTOP NAV */}
			{isNonMobileScreens ? (
				<FlexBetween gap="2rem">
					{/* <IconButton onClick={() => dispatch(setMode())}>
						{theme.palette.mode === "dark" ? <DarkMode sx={{ fontSize: "25px" }} /> : <LightMode sx={{ color: universalMedium, fontSize: "25px" }} />}
					</IconButton> */}
					{/* <Message sx={{ fontSize: "25px" }} /> */}

					{/* <Help sx={{ fontSize: "25px", color: universalMedium }} /> */}
					{/* <FormControl variant="standard" value={fullName}>
						<Select
							value={fullName}
							sx={{
								backgroundColor: neutralLight,
								width: "150px",
								borderRadius: "0.25rem",
								p: "0.25rem 1rem",
								"& .MuiSvgIcon-root": {
									pr: "0.25rem",
									width: "3rem",
								},
								"& .MuiSelect-select:focus": {
									backgroundColor: neutralLight,
								},
							}}
							input={<InputBase />}
						>
							<MenuItem value={fullName}>
								<Typography>{fullName}</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => {
									dispatch(setLogout());
									navigate("/");
								}}
							>
								Log Out
							</MenuItem>
						</Select>
					</FormControl> */}
					<Grid item>
						<Tooltip title={`You have ${badgeContent} notifications`}>
						<IconButton onClick={handleClickOpen} anchorE1={anchorE1}>
							<Badge badgeContent={badgeContent} color="primary">
							<Notifications sx={{ fontSize: "25px", color:"white" }} />
							</Badge>
						</IconButton>
						</Tooltip>
						<Menu
						style={{ marginRight: "30px" }}
						anchorEl={anchorE1}
						open={openNotifications}
						onClose={handleClose}
						>
						{notiificationList.length === 0 ? (
							<MenuItem>
							<p>No Notifications Available</p>
							</MenuItem>
						) : (
							notiificationList.map((row, index) => {
							return (
								<>
								<MenuItem
									style={{
									color: "black",
									margin: "5px 0 5px 0",
									fontSize: "12px",
									// minWidth: "200px",
									maxWidth: "500px",
									textWrap: "pretty",
									borderRadius: "5px",
									}}
									onClick={() => {
									handleClose();
									}}
								>
									<span>
									<p style={{ fontStyle: "italic" }}>{row.message}</p>{" "}
									<p>{row.description}</p>{" "}
									</span>
								</MenuItem>
								<hr />
								</>
							);
							})
						)}
						</Menu>
					</Grid>
					<NavMenu name={fullName} />
				</FlexBetween>
			) : (
				<IconButton onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)} sx={{ color: white }}>
					<MenuIcon />
				</IconButton>
			)}

			{/* MOBILE NAV */}
			{!isNonMobileScreens && isMobileMenuToggled && (
				<Box position="fixed" right="0" bottom="0" height="100%" zIndex="10" maxWidth="500px" minWidth="285px" backgroundColor={white}>
					{/* CLOSE ICON */}
					<Box display="flex" justifyContent="flex-end" p="1rem" sx={{ marginRight: "15px" }}>
						<IconButton onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}>
							<span style={{ fontSize: "14px" }}>Close</span>
							<Close />
						</IconButton>
					</Box>

					{/* MENU ITEMS */}
					<FlexBetween display="flex" flexDirection="column" justifyContent="start" alignItems="start" gap="1rem">
						<span style={{ margin: "0 0 15px", width: "80%" }}>
							<NavAvatar name={fullName} />
						</span>
						<span
							style={{
								fontSize: "18px",
								display: "flex",
								alignItems: "center",
								width: "80%",
								marginBottom: "15px",
							}}
						>
							<Grid item>
								<Tooltip title={`You have ${badgeContent} notifications`}>
								<IconButton onClick={handleClickOpen} anchorE1={anchorE1}>
									<Badge badgeContent={badgeContent} color="primary">
									<Notifications sx={{ fontSize: "25px", color:"white" }} />
									</Badge>
								</IconButton>
								</Tooltip>
								<Menu
								style={{ marginRight: "30px" }}
								anchorEl={anchorE1}
								open={openNotifications}
								onClose={handleClose}
								>
								{notiificationList.length === 0 ? (
									<MenuItem>
									<p>No Notifications Available</p>
									</MenuItem>
								) : (
									notiificationList.map((row, index) => {
									return (
										<>
										<MenuItem
											style={{
											color: "black",
											margin: "5px 0 5px 0",
											fontSize: "12px",
											// minWidth: "200px",
											maxWidth: "500px",
											textWrap: "pretty",
											borderRadius: "5px",
											}}
											onClick={() => {
											handleClose();
											}}
										>
											<span>
											<p style={{ fontStyle: "italic" }}>{row.message}</p>{" "}
											<p>{row.description}</p>{" "}
											</span>
										</MenuItem>
										<hr />
										</>
									);
									})
								)}
								</Menu>
							</Grid>
						</span>
						{/* <span
							style={{
								fontSize: "18px",
								display: "flex",
								alignItems: "center",
								width: "80%",
								marginBottom: "15px",
							}}
						>
							<Help sx={{ fontSize: "25px", marginRight: "10px" }} />
							Help
						</span> */}
						<span
							onClick={logout}
							style={{
								fontSize: "18px",
								display: "flex",
								alignItems: "center",
								width: "80%",
								marginBottom: "15px",
							}}
						>
							<FaSignOutAlt style={{ fontSize: "25px", marginRight: "10px" }} />
							Logout
						</span>
					</FlexBetween>
				</Box>
			)}
		</FlexBetween>
	);
};

export default Navbar;
