import React, {useState} from "react";
import { Grid, Box } from "@mui/material";
import FormDialog from "components/modal";
import SettingsPill from "components/SettingsPill";
import { Divider } from "@mui/material";
import IdUploadForm from "components/Forms/UserAgreementForms/idUploadForm";
import UserAgreementUploadForm from "components/Forms/UserAgreementForms/UserAgreementUploadForm";
import { baseURL } from "services/API";
import axios from "axios";
import { useSelector } from "react-redux";

export default function UserAgreementDocuments() {
    const landlordId = useSelector((state) => state.landlord);
    const token = useSelector((state) => state.token);
	const [openUploadDocument, setOpenUploadDocument] = useState(false);
    const [openBackIdUpload, setOpenBackIdUpload] = useState(false);
    const [openFrontIdUpload, setOpenFrontIdUpload] = useState(false);
    const [openAgreementUpload, setOpenAgreementUpload] = useState(false);
    const [newUploadDocument, setNewUploadDocument] = useState("")
    const [confirmUploadDocument, setConfirmUploadDocument] = useState("")
	const [details, setDetails] = useState("");
	const [modal, setModal] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

    const handleClickOpenUploadDocument = async () => {
        let formData = new FormData
        formData.append('id', landlordId)

        try {
            const response = await axios.post(`${baseURL}/landlords/get_business_terms`, formData,
                {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                }
            );
            const url = `${baseURL}${response.data.data.business_terms_document_gen}`;
            const a = document.createElement("a");
            a.href = url;
            a.download = url.split("/").pop();
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (err) {
            return
        }
        
	};

	const handleCloseUploadDocument = () => {
		setModal("");
		setOpenUploadDocument(false);
	};

    const handleClickOpenBackIdUpload = () => {
		setOpenBackIdUpload(true);
	};

	const handleCloseBackIdUpload = () => {
		setModal("");
		setOpenBackIdUpload(false);
	};

    const handleClickOpenFrontIdUpload = () => {
		setOpenFrontIdUpload(true);
	};

	const handleCloseFrontIdUpload = () => {
		setModal("");
		setOpenFrontIdUpload(false);
	};

    const handleClickOpenAgreementUpload = () => {
		setOpenAgreementUpload(true);
	};

	const handleCloseAgreementUpload = () => {
		setModal("");
		setOpenAgreementUpload(false);
	};

    const handleChangeNewUploadDocument = (event) => {
		setNewUploadDocument(event.target.value);
	};

	const handleChangeConfirmUploadDocument = (event) => {
		setConfirmUploadDocument(event.target.value);
	};

    const handleSubmitChangeUploadDocument = () => {
        
    }


	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
                    <h1>User Agreement Documents</h1>
                    <h3>RENT COLLECTION SERVICE AGREEMENT</h3>
                    <Box sx={{border: "1px solid black", padding: "10px", marginBottom:"2rem"}}>
                        <SettingsPill pillTitle={"TERMS OF BUSINESS"} pillDescription={"Download, Read and Sign the working terms of business document"} buttonText={"Download Document"} buttonAction={handleClickOpenUploadDocument} buttonText2={"Upload Signed Document"}  buttonAction2={handleClickOpenAgreementUpload}/>
                        <Divider />
                    </Box>

                    <h3>NATIONAL IDENTITY CARD</h3>
                    <Box sx={{border: "1px solid black", padding: "10px", marginBottom:"2rem"}}>
                        <SettingsPill pillTitle={"Front of ID Card"} pillDescription={"Attach the a photo of the FRONT part of your National ID"} buttonText={"Upload Photo"} buttonAction={handleClickOpenFrontIdUpload}/>
                        <Divider />
                        <SettingsPill pillTitle={"Back of ID Card"} pillDescription={"Attach the a photo of the BACK part of your National ID"} buttonText={"Upload Photo"} buttonAction={handleClickOpenBackIdUpload}/>
                        <Divider />
                    </Box>
				</Grid>
			</Grid>

            <FormDialog
                open={openAgreementUpload}
                handleClose={handleCloseAgreementUpload}
                title={"Upload the signed Rent Collection Agreement"}
                handleSubmit={handleSubmitChangeUploadDocument}
                buttonText="Confirm Upload"
                enableActions={true}
                buttonClass={"add-button"}
                content={
                    <UserAgreementUploadForm/>
                }
            />   

            <FormDialog
                open={openFrontIdUpload}
                handleClose={handleCloseFrontIdUpload}
                title={"Upload the FRONT of you National ID"}
                handleSubmit={handleSubmitChangeUploadDocument}
                buttonText="Confirm Upload"
                enableActions={true}
                buttonClass={"add-button"}
                content={
                    <IdUploadForm/>
                }
            />   

            <FormDialog
                open={openBackIdUpload}
                handleClose={handleCloseBackIdUpload}
                title={"Upload the BACK of you National ID"}
                handleSubmit={handleSubmitChangeUploadDocument}
                buttonText="Confirm Upload"
                enableActions={true}
                buttonClass={"add-button"}
                content={
                    <IdUploadForm/>
                }
            />            
		</Box>
	);
}
