import { Box, useMediaQuery, Tab, Tabs, Typography, Grid } from "@mui/material";
import * as React from 'react';
import PropTypes from 'prop-types';
import UserAccountSettings from "./userAccountSettings";
import UserAgreementDocuments from "./userAgreementDocuments";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isFailed, setIsFailed]  = React.useState(false);

  React.useEffect(() =>{
    const timer = setTimeout(() => {
      setIsSuccess(false)
      setIsFailed(false)
    }, 3000);
    return () => clearTimeout(timer);
  }, [isSuccess, isFailed]);


  return (
    <Box m="20px">
          <Grid container spacing={2}>
			 <Grid item xs={12}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs fullWidth value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                  <Tab label="User Account Settings" {...a11yProps(0)} />   
                  <Tab label="User Agreement Documents" {...a11yProps(1)} />     
                  <Tab label="Deleted Properties" {...a11yProps(2)} />               
                </Tabs>
              </Box>
              <Box>
                <TabPanel value={value} index={0}><UserAccountSettings /></TabPanel>
                <TabPanel value={value} index={1}><UserAgreementDocuments /></TabPanel>
              </Box>
           </Grid>
          </Grid>
    </Box>
  );
};

export default Settings;