import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

export default function BottomCard({ cardTitle, cardMoney }) {
	return (
		<Box sx={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			marginY: "10px",
            border: "1px solid grey",
            padding: "10px",
            marginX: "5px",
            borderRadius: "14px"
		}}>
			<Box>
                <Typography sx={{ fontWeight: 350, marginY: "10px" }} variant="h6" component="div">
					{cardTitle}
				</Typography>
				<Typography sx={{ fontWeight: 650 }} variant="h3" component="div">
					{cardMoney}
				</Typography>
			</Box>
		</Box>
	);
}