import * as React from 'react';
import { Grid, Box, Typography } from "@mui/material";
import PaymentsTable from 'components/Tables/PaymentsTable';
import DummyTable from 'components/Tables/DummyTable';
import ProgressScale from 'components/ProgressScale';
import BasicSelect from 'components/select';
import FormDialog from 'components/modal';
import { useGetPaymentsData, useRentals } from 'services/hooks/rentals';
import PlainCard from 'components/PlainCard';
import FlexBetween from 'components/FlexBetween';

export default function Payments() {
    const [rental, setRental] = React.useState('')
    const [open, setOpen] = React.useState(false);
    const {paymentsData, isLoadingPaymentsData} = useGetPaymentsData()
    const {rentals } = useRentals()

    const handleSelectChange = (event) => {
        setRental(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };
 

    return (
			<Box m="20px">
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{isLoadingPaymentsData && <ProgressScale />}
						<Typography variant="h2" fontWeight={"bold"}>Tenant Payments</Typography>
						<Box sx={{ display:'flex', margin:"0.7rem", justifyContent:"space-between" }}>
							<PlainCard name="Total Amount Received" value={0} />

							<PlainCard name="Total Amount Due" value={0} />

							<PlainCard name="Overall Total" value={0} />
						</Box>
						<Grid container spacing={2}>
							<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
								<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
							</Grid>
							<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
								
							</Grid>
							<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
								<BasicSelect value={rental} handleChange={handleSelectChange} label={"Filter By Rental"} data={rentals} />
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{ marginTop: 3 }}>
								{isLoadingPaymentsData ? <DummyTable /> : <PaymentsTable rows={paymentsData} />}
							</Grid>
						</Grid>
						<FormDialog open={open} handleClose={handleClose} />
					</Grid>
				</Grid>
			</Box>
		);
}