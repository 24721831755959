import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { baseURL } from "services/API";
import { Grid, Box } from "@mui/material";
import TenantsTable from "components/Tables/TenantsTable";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import BasicSelect from "components/select";
import FormDialog from "components/modal";
import TenantsForm from "components/Forms/TenantsForm";
import DummyTable from "components/Tables/DummyTable";
import ProgressScale from "components/ProgressScale";
import { useGetRentalTenants, useRentals } from "services/hooks/rentals";
import { setRefreshCount, setOccupancyId } from "state";
import TopSnack from "components/TopSnack";
import TenantsPopover from "components/PopOvers/TenantsPopover";
import TenantMessageForm from "components/Forms/TenantMessageForm";
import TenantUpdateForm from "components/Forms/TenantUpdateForm";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import moment from "moment";

export default function Tenants() {
	const token = useSelector((state) => state.token);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	const [rental, setRental] = React.useState("");
	const [unit, setUnit] = React.useState("");
	const [message, setMessage] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [openTenantDetails, setOpenTenantDetails] = React.useState(false);
	const [openSnack, setOpenSnack] = React.useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [severity, setSeverity] = React.useState("");
	const { rentalTenants, isLoadingRentalTenants} = useGetRentalTenants();
	const { rentals, isLoadingRentals } = useRentals();
	const [isLoadedUnits, setIsLoadedUnits] = React.useState(false);
	const [units, setUnits] = React.useState([]);
	const [tenantDetails, setTenantDetails] = React.useState({});
	const [loadedTenant, setLoadedTenant] = React.useState(false);
	const [loadingTenantsError, setLoadingTenantsError] = React.useState("");
	const [isExistingTenant, setIsExisitingTenant] = React.useState(true);
	const [loadingTenantDetails, setLoadingTenantDetails] = React.useState(true);
	const [errorLoadingTenantDetails, setErrorLoadingTenantDetails] = React.useState(false);
	const [modal, setModal] = React.useState("");
	const [details, setDetails] = React.useState("");
	const [newTenantDetails, setNewTenantDetails] = React.useState({
		rent_deposit_amount: 0, 
		months_covered:0, 
		next_payment_date:moment().format('YYYY-MM-DD'),
		first_name: "",
		last_name: "",
		tenant_number: "",
		phone_number:"",
		unit_id: "",
	});
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	const handleSelectChange = async (event) => {
		setIsLoadedUnits(false);
		setRental(event.target.value);
		try {
			const response = await axios.get(`${baseURL}/landlords/rentals/units/unoccupied?rental_id=${event.target.value}`);
			setUnits(response.data.data);
			setNewTenantDetails((prev) => ({ ...prev, ['unit_id']: '' }));
			setIsLoadedUnits(true);
			return true;
		} catch (err) {
			return false;
		}
	};

	const handleChangeTenantDetails = (event) => {
		const { name, value } = event.target;
    	setNewTenantDetails((prev) => ({ ...prev, [name]: value }));
		if (!toString(value).trim()) {
      		setErrors((prev) => ({ ...prev, [name]: `${name} is required`}));
		} else {
			setErrors((prev) => ({ ...prev, [name]: '' }));
		}
	};

	const handleClickOpen = () => {
		setLoadingTenantDetails(false);
		setLoadingTenantsError("");
		setOpen(true);
	};

	const handleClose = () => {
		setModal("");
		setErrors({});
		setOpen(false);
		setLoadedTenant(false);
		setIsExisitingTenant(true);
		setIsLoadedUnits(false);
		setNewTenantDetails({
			rent_deposit_amount: 0, 
			months_covered:0, 
			next_payment_date:moment().format('YYYY-MM-DD'),
			first_name: "",
			last_name: "",
			tenant_number: "",
			unit_id: "",
		});
	};

	const handleClickOpenTenantDetails = () => {
		setOpenTenantDetails(true);
	};

	const handleCloseTenantDetails = () => {
		setOpenTenantDetails(false);
	};

	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack({ ...openSnack, open: false });
	};

	const handleClickPopOver = (event, row) => {
		setAnchorEl(event.currentTarget);
		setDetails(row)
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const handleNavigateTenantPayments = (occupancy) => {
		dispatch(setOccupancyId(occupancy))
		navigate("/tenants/payments")
	}

	const handleChangeTenantNumber = async (e) => {
		setLoadedTenant(false);
		setIsExisitingTenant(true);
		setIsLoadedUnits(false);
		setLoadingTenantsError("");
		const phone_number = e.target.value;
		if (!phone_number.trim()) {
      		setErrors((prev) => ({ ...prev, 'phone_number': `The Tenant's phone number is required`}));
		} else {
			setErrors((prev) => ({ ...prev, 'phone_number': '' }));
		}
		if (phone_number.length === 12) {
			setNewTenantDetails((prev) => ({ ...prev, ['phone_number']: phone_number }));
			try {
				const response = await axios.get(`${baseURL}/tenants?username=${phone_number}`);
				setTenantDetails(response.data.data);
				setNewTenantDetails((prev)=> ({...prev, tenant_number: response.data.data.tenant_number}))
				setLoadedTenant(true);
				setLoadingTenantDetails(false);
				setLoadingTenantsError("");
				return true;
			} catch (err) {
				setIsExisitingTenant(false);
				setLoadingTenantDetails(false);
				setErrorLoadingTenantDetails(true);
				setLoadingTenantsError("");
				return false;
			}
		} else {
			setLoadingTenantsError("Please type a complete phone number with 12 digits including 256");
			
			setNewTenantDetails((prev) => ({ ...prev, "unit_id": "","rent_deposit_amount": undefined,"months_covered": undefined, }));
		}
	};

	const [errors, setErrors] = React.useState({});
	const propertySchema = isExistingTenant ? yup.object().shape({
		unit_id: yup.string().required("Please select a unit attached to the property above"),
		tenant_number: yup.string().required("Input a the tenant's phone number to start the process"),
		rent_deposit_amount: yup.number().required("This field is required"),
		months_covered: yup.number().required("This field is required"),
		next_payment_date: yup.date().required("This field is required"),
	}) : yup.object().shape({
		unit_id: yup.string().required("Please select a unit attached to the property above"),
		// tenant_number: yup.string().required("Input a the tenant's phone number to start the process"),
		first_name: yup.string().required("Input the tenant's First Name"),
		last_name: yup.string().required("Input the tenant's Last Name"),
		rent_deposit_amount: yup.number().required("This field is required"),
		months_covered: yup.number().required("This field is required"),
		next_payment_date: yup.date().required("This field is required"),

	}) ;

	const handleSubmitTenant = async () => {
		try {
			await propertySchema.validate(newTenantDetails, {abortEarly:false});
		} catch (error) {
			const newError = {}
			error.inner.forEach(err => {
				newError[err.path]=err.message
			})
			setErrors(newError)
			return
		}
		
		try {
			if (isExistingTenant) {
				await axios.post(`${baseURL}/landlords/rentals/units/assign`, {
					unit_id: newTenantDetails.unit_id,
					tenant_number: newTenantDetails.tenant_number,
					rent_deposit_amount: newTenantDetails.rent_deposit_amount, 
					months_covered:newTenantDetails.months_covered, 
					next_payment_date:newTenantDetails.next_payment_date,
				});
			} else {
				const response = await axios.post(`${baseURL}/accounts/users`, {
					first_name: newTenantDetails.first_name,
					last_name: newTenantDetails.last_name,
					email: "dummy@email.com",
					phone_number: newTenantDetails.phone_number.slice(3),
					password: "@Rent123",
					username: `+${newTenantDetails.phone_number}`,
					to_activate: "True",
					unit_id: newTenantDetails.unit_id,
					rent_deposit_amount: newTenantDetails.rent_deposit_amount, 
					months_covered:newTenantDetails.months_covered, 
					next_payment_date:newTenantDetails.next_payment_date,
				});
			}
			setOpen(false);
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("success");
			setMessage("Tenant Added Successfully");
			dispatch(setRefreshCount());
			setLoadedTenant(false);
			setIsExisitingTenant(true);
			setIsLoadedUnits(false);
			setNewTenantDetails({
				rent_deposit_amount: 0, 
				months_covered:0, 
				next_payment_date:moment().format('YYYY-MM-DD'),
				first_name: "",
				last_name: "",
				tenant_number: "",
				phone_number:"",
				unit_id: "",
			});
		} catch (err) {
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("error");
			setMessage("Tenant Addition failed, Please check data provided");
		}
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingRentalTenants && <ProgressScale />}
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<h1 className="pageHeader">Tenants</h1>
						<Button
							sx={{ display: { xs: "flex", sm: "none" }, marginLeft: "8%" }}
							className="add-button"
							variant="contained"
							onClick={handleClickOpen}
							endIcon={<AddIcon />}
						>
							Add Tenant
						</Button>
					</Box>
					<Grid container spacing={2}>
						<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect
								value={rental}
								handleChange={handleSelectChange}
								label={"Filter Rental"}
								data={rentals}
								isLoading={isLoadingRentals}
								id={"main-tenants-rental-select"}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
							<Button
								sx={{ display: { xs: "none", sm: "flex" } }}
								className="add-button"
								variant="contained"
								onClick={handleClickOpen}
								endIcon={<AddIcon />}
							>
								Add Tenant
							</Button>
						</Grid>
						<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
							<BasicSelect
								value={rental}
								handleChange={handleSelectChange}
								label={"Filter Rental"}
								data={rentals}
								isLoading={isLoadingRentals}
								id={"main-tenants-rental-select"}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingRentalTenants ? (
								<DummyTable />
							) : (
								<TenantsTable
									setDetails={setDetails}
									rows={rentalTenants}
									isLoadingRentalTenants={isLoadingRentalTenants}
									handleClickPopOver={handleClickPopOver}
									id={popperId}
									handleNavigateTenantPayments={handleNavigateTenantPayments}
								/>
							)}
						</Grid>
					</Grid>
					<TenantsPopover
						popperId={popperId}
						openPopper={openPopper}
						anchorEl={anchorEl}
						handleClickOpen={handleClickOpen}
						handleClosePopOver={handleClosePopOver}
						handleClickOpenTenantDetails={handleClickOpenTenantDetails}
						handleNavigateTenantPayments={handleNavigateTenantPayments}
						details={details}
						handleModalView={handleModalView}
						navigate={navigate}
					/>

					{modal === "edit" ? (
						<FormDialog
							title="Edit Tenant"
							enableActions={true}
							content={<TenantUpdateForm details={details} />}
							open={open}
							handleClose={handleClose}
							buttonText="update"
						/>
					) : modal === "message" ? (
						<FormDialog
							title={`Sending Message to ${details.related_tenant.first_name}`}
							enableActions={true}
							content={<TenantMessageForm />}
							open={open}
							handleClose={handleClose}
							buttonText="submit"
						/>
					) : modal === "delete" ? (
						<FormDialog
							title="Delete Tenant"
							enableActions={true}
							open={open}
							content="Are you sure you want to delete this Tenant?"
							handleClose={handleClose}
							buttonText="delete"
						/>
					) : (
						<FormDialog
							open={open}
							handleClose={handleClose}
							title={"Add A New Tenant"}
							handleSubmit={handleSubmitTenant}
							enableActions={true}
							buttonText="Add Tenant"
							buttonClass={"add-button"}
							content={
								<TenantsForm
									handleChangeTenantNumber={handleChangeTenantNumber}
									rentals={rentals}
									tenant={tenantDetails}
									errorTenant={errorLoadingTenantDetails}
									loadedTenant={loadedTenant}
									errors={errors}
									unit={unit}
									units={units}
									isLoadedUnits={isLoadedUnits}
									handleChangeRental={handleSelectChange}
									isExistingTenant={isExistingTenant}
									handleChangeTenantDetails={handleChangeTenantDetails}
									loadingTenantsError={loadingTenantsError}
								/>
							}
						/>
					)}

					<TopSnack
						vertical={openSnack.vertical}
						horizontal={openSnack.horizontal}
						open={openSnack.open}
						handleCloseSnack={handleCloseSnack}
						message={message}
						severity={severity}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
