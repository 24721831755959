import { Box, Paper, MenuItem, Grid, TextField, Typography, Divider, Card, CardActions, CardContent  } from "@mui/material";
import DoughnutChart from "components/Graphs/DoughnutChart";
import ReusableDoughnutChart from "components/Graphs/ReusableDoughnutChart ";
import BarChart from "components/Graphs/BarChart";
import { FlexCenter, FlexColCenter, FlexEnd, FlexStart } from "components/FlexBox/FlexBox";
import { currencyFormatter } from "utilities/currencyFormatter";
import { MdBalance } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { BsCart4 } from "react-icons/bs";
import { Link } from "react-router-dom";
import { usePropertyDashboardStatistics, usePropertyDashboardCards, usePropertyDashboardRevenue } from "services/hooks/rentals";
import { useSelector } from "react-redux";
import DummyCard from "components/DummyCard";

export default function PropertyDashboard({handleChange}) {
    const id = useSelector((state) => state.property_id);
    const {propertyDashboardStatistics, isLoadingPropertyDashboardStatistics , errorPropertyDashboardStatistics} = usePropertyDashboardStatistics(id);
    const {propertyDashboardCards, isLoadingPropertyDashboardCards , errorPropertyDashboardCards} = usePropertyDashboardCards(id);
    const {propertyDashboardRevenue, isLoadingPropertyDashboardRevenue , errorPropertyDashboardRevenue} = usePropertyDashboardRevenue(id);

    const rentCollectionDataSet =  [
		{
			label: "Payments Collected",
			data: [propertyDashboardStatistics.collection_rate.paid, propertyDashboardStatistics.collection_rate.unpaid],
			backgroundColor: [
				"rgba(255, 99, 132, 0.2)",
				"rgba(98, 245, 162, 0.6)",
			],
			borderColor: [
				"rgba(255, 99, 132, 1)",
				"rgba(54, 162, 235, 1)",
			],
			borderWidth: 1,
		},
	]
    
    const propertyOccupancyDataSet = [
		{
			label: "Payments Collected",
			data: [propertyDashboardStatistics.occupancy_rate.occupied, propertyDashboardStatistics.occupancy_rate.unoccupied],
			backgroundColor: [
				"rgba(255, 99, 132, 0.2)",
				"rgba(98, 245, 162, 0.6)",
			],
			borderColor: [
				"rgba(255, 99, 132, 1)",
				"rgba(54, 162, 235, 1)",
			],
			borderWidth: 1,
		},
	]

    const revenueMonthsDataSet = propertyDashboardRevenue.months
    const revenueBalanceDataSet = propertyDashboardRevenue.balances
    const newMessages=propertyDashboardCards.cards.new_messages;
    const openTickets = propertyDashboardCards.cards.open_tickets;
    const rentArrears = propertyDashboardCards.cards.rent_arrears;
    const rentCollected = propertyDashboardCards.cards.rent_collected;
    const closedTickets = propertyDashboardCards.cards.closed_tickets;


    return(
       <Box m="5px">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="h3">Property Portfolio Dashboard</Typography>
                    </div>
                    {isLoadingPropertyDashboardStatistics ? (
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <DummyCard />
                                </Grid>
                                <Grid item xs={3}>
                                    <DummyCard />
                                </Grid>
                                <Grid item xs={3}>
                                    <DummyCard />
                                </Grid>
                                <Grid item xs={3}>
                                    <DummyCard />
                                </Grid>
                            </Grid>
                    ) : (
                        <>
                            <Grid container spacing={1} sx={{ margin: "0.5rem" }}>
                                <Grid container xs={8}>
                                    <Box sx={{ display: "flex", justifyContent:"space-between",width:"100%" }}>
                                        <Box sx={{ display:"flex", flexDirection:"column", padding:"10px", width:"100%", margin:"0 1rem 0" }}>
                                            <FlexColCenter sx={{ padding:"1rem", width: "100%"}}>
                                                <Typography variant="h6" fontWeight="bold">Rent Collection Rate</Typography>
                                                <ReusableDoughnutChart labels={['So far paid', 'Rent Due']} datasets={rentCollectionDataSet} />
                                            </FlexColCenter>
                                        </Box>
                                        <Box sx={{ display:"flex", flexDirection:"column", padding:"10px", width:"100%", margin:"0 1rem 0" }}>
                                            <FlexColCenter sx={{ padding:"1rem", width: "100%"}}>
                                                <Typography variant="h6" fontWeight="bold">Occupancy Rate</Typography>
                                                <ReusableDoughnutChart labels={['Occupied', 'Unoccupied']} datasets={propertyOccupancyDataSet} />
                                            </FlexColCenter>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid container spacing={2} xs={4} sx={{ padding:"10px" }}>
                                    <Box sx={{ display:"flex", flexDirection:"column", padding:"10px", width:"100%", margin:"0 1rem 0" }}>
                                        <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                            <CardContent>
                                                <Box sx={{ display:"flex", flexDirection:"row" }} >
                                                    <Box sx={{ width:'70%' }}>
                                                        <Typography variant="h4" fontWeight="bold">Total Units</Typography>
                                                        {/* <Typography variant="overline"> from {newMessages.units} units</Typography> */}
                                                        <Typography variant="h2" fontWeight="bold">{newMessages.number}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Link onClick={()=>handleChange("e", 2)}> 
                                                            <Typography variant="body1" >View Details &gt;</Typography>
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                        
                                        <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                            <CardContent>
                                                <Box sx={{ display:"flex", flexDirection:"row" }} >
                                                    <Box sx={{ width:'70%' }}>
                                                        <Typography variant="h4" fontWeight="bold">Current Tenants</Typography>
                                                        {/* <Typography variant="overline"> from {closedTickets.units} units</Typography> */}
                                                        <Typography variant="h2" fontWeight="bold">{closedTickets.number}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Link onClick={()=>handleChange("e", 3)}>
                                                            <Typography variant="body1" >View Details &gt;</Typography>
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                            <CardContent>
                                                <Box sx={{ display:"flex", flexDirection:"row" }} >
                                                    <Box sx={{ width:'70%' }}>
                                                        <Typography variant="h4" fontWeight="bold">Open Tickets</Typography>
                                                        <Typography variant="overline"> from {openTickets.units} units</Typography>
                                                        <Typography variant="h2" fontWeight="bold">{openTickets.number}</Typography>

                                                    </Box>
                                                    <Box>
                                                        <Link onClick={()=>handleChange("e", 5)}>
                                                            <Typography variant="body1" >View Details &gt;</Typography>
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ margin: "0.5rem" }}>
                                <Grid item xs={8} >
                                    <Paper sx={{ padding:"1rem", width: "100%"}}>
                                        <Grid container xs={12}>
                                            <Grid item xs={12} sx={{ margin:"1rem" }}>
                                                <Typography variant="h6" fontWeight="bold">Revenue Overvue</Typography>
                                                <Typography variant="body2">Your property finance report for year 2024</Typography>
                                                <Divider/>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={8} sx={{ padding:"0.5rem" }} >
                                                    <BarChart
                                                        label = {revenueMonthsDataSet.map(x=>x.alias)}
                                                        data={[
                                                            {
                                                                label: "Income",
                                                                data: revenueMonthsDataSet.map(x=>x.income),
                                                                fill: false,
                                                                backgroundColor: "rgba(135, 211, 124)",
                                                                tension: 0.1,
                                                            },
                                                            {
                                                                label: "Expense",
                                                                data: revenueMonthsDataSet.map(x=>x.expense),
                                                                fill: false,
                                                                backgroundColor: "rgba(30, 139, 195)", 
                                                                tension: 0.1,
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                                <Divider variant="middle" orientation="vertical"/>
                                                <Grid item xs={3.7} sx={{ padding:"0.5rem"}}>
                                                    <FlexColCenter sx={{ display:"flex", flexDirection:"column", padding:"0.5rem",  }}>
                                                        <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                            <Typography sx={{ color: "#A05ACE", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "#ffebff"  }} variant="h2">
                                                                {<MdBalance/>}
                                                            </Typography>
                                                            <div>
                                                                <Typography variant="h5" fontWeight="bold">{currencyFormatter(parseFloat(revenueBalanceDataSet.total_balance))}</Typography>
                                                                <Typography variant="body2">Total Balance</Typography>
                                                            </div>
                                                        </Box>
                                                        <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                            <Typography sx={{ color: "green", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "rgba(135, 211, 124)" }} variant="h2">
                                                                {<GiCash/>}
                                                            </Typography>
                                                            <div>
                                                                <Typography variant="h5" fontWeight="bold">{currencyFormatter(parseFloat(revenueBalanceDataSet.total_income))}</Typography>
                                                                <Typography variant="body2">Total Income</Typography>
                                                            </div>
                                                        </Box>
                                                        <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                            <Typography sx={{ color: "rgba(30, 139, 195)", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "lightblue"  }} variant="h2">
                                                                {<BsCart4/>}
                                                            </Typography>
                                                            <div>
                                                                <Typography variant="h5">{currencyFormatter(parseFloat(revenueBalanceDataSet.total_expense))}</Typography>
                                                                <Typography variant="body2">Total Expense</Typography>
                                                            </div>
                                                        </Box>
                                                    </FlexColCenter>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid container spacing={2} xs={4}  sx={{ padding:"10px" }} >
                                <Box sx={{ display:"flex", flexDirection:"column", padding:"10px", width:"100%", margin:"0 1rem 0" }}>
                                        <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                            <CardContent>
                                                <Box sx={{ display:"flex", flexDirection:"row" }} >
                                                    <Box sx={{ width:'70%' }}>
                                                        <Typography variant="h4" fontWeight="bold">Rent Arrears</Typography>
                                                        <Typography variant="overline"> from {rentArrears.units} units</Typography>
                                                        <Typography variant="h2" fontWeight="bold">{currencyFormatter(parseFloat(rentArrears.amount))}</Typography>
                                                        <Typography variant="body2"> as of {rentArrears.as_of}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Link onClick={()=>handleChange('e', 5)}>
                                                            <Typography variant="body1" >View Details &gt;</Typography>
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                        <Card variant="outlined" sx={{ margin:"0.5rem" }}>
                                            <CardContent>
                                                <Box sx={{ display:"flex", flexDirection:"row" }} >
                                                    <Box sx={{ width:'70%' }}>
                                                        <Typography variant="h4" fontWeight="bold">Rent Collected</Typography>
                                                        <Typography variant="overline"> from {rentCollected.units} units</Typography>
                                                        <Typography variant="h2" fontWeight="bold">{currencyFormatter(parseFloat(rentCollected.amount))}</Typography>
                                                        <Typography variant="body2"> out of {currencyFormatter(parseFloat(rentCollected.total))}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Link onClick={()=>handleChange('e', 3)}>
                                                            <Typography variant="body1" >View Details &gt;</Typography>
                                                        </Link>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                    
                    )}
                </Grid>
            </Grid>
            
	    </Box>
    )
}