import React, {useEffect, useState} from "react";
import { Box, } from "@mui/material";
import CustomTab from "components/Tabs/Tab";
import { useLocation } from "react-router-dom";
import Tickets from "scenes/Tickets";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTab } from "state";

const facilityIcons = {
  marginRight: "8px",
  fontSize: "20px",
  color: "#52524e",
};

const coloredIconGreen = {
  marginRight: "8px",
  fontSize: "20px",
  color: "#0af047",
};

const coloredIconRed = {
  marginRight: "8px",
  fontSize: "20px",
  color: "#f20a0a",
};

const detailsText = {
  fontSize: "16px",
  fontWeight: 600,
  padding: "5px",
};

const descriptionText = {
  fontSize: "15px",
  fontWeight: 400,
  fontStyle: "italic",
};




const TicketsTab = () => {
    const location = useLocation();
    const id  = useSelector((state) => state.property_id);
    const [value, setValue] = React.useState(useSelector((state)=> state.currentTab['ticketsTab']) );
    const dispatch = useDispatch()
    const handleChange = (event, newValue) => {
      setValue(newValue);
      dispatch(setCurrentTab({ name: "ticketsTab", tab: newValue }));
    };
    

    const tabComponents = [
      { Component: <Tickets/>, label: "Open Tickets", index: 0 },
      { Component: <></>, label: "Closed Tickets", index: 1,},
      
    ]; 


    return (
      <Box >
        <CustomTab tabComponents={tabComponents} value={value} handleChange={handleChange} />
      </Box>
    );

}

export default TicketsTab;