import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  Alert
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import { baseURL } from "services/API";
import LinearProgress from '@mui/material/LinearProgress';
import { tokens } from "../../theme";
import axios from "axios";

const registerSchema = yup.object().shape({
  first_name: yup.string().required("required"),
  last_name: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  phone_number: yup.string().required("required"),
  business_name: yup.string().required("required"),
  username: yup.string().required("required").test(
      'check-username',
      'Username is already taken. Please Input another', // Error message if not unique
      async (value) => {
        if (!value) return true; // Skip validation if value is empty
        try {
          let formData = new FormData();
          formData.append("phone_number", value);
          const usernameCheck = await axios.post(`${baseURL}/accounts/users/details`, formData)
          return false; // Validation fails if username is taken
        } catch (error) {
          return true; // Validation passes if username is available
        }
      }),
  password: yup.string().required("required"),
});

const loginSchema = yup.object().shape({
  username: yup.string().required("required"),
  password: yup.string().required("required"),
});

const initialValuesRegister = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  business_name: "",
  username: "",
  password: "",
};

const initialValuesLogin = {
  username: "",
  password: ""
};

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isLogin = pageType === "login";
  const isRegister = pageType === "register";
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")

  const register = async (values, onSubmitProps) => {
    // this allows us to send form info with image

    // const formData = new FormData();
    // for (let value in values) {
    //   formData.append(value, values[value]);
    // }
    // formData.append("picturePath", values.picture.name);

    const savedUserResponse = await fetch(
      `${baseURL}/accounts/users/landlords`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      }
    );
    
    const savedUser = await savedUserResponse.json();
    if (savedUser.status===201) {
      onSubmitProps.resetForm();
      setPageType("login");setPageType("login");
    }else{
    setError("Registration Failed, Please try again");
    }
    
    
  };

  const login = async (values, onSubmitProps) => {
    setIsLoading(true)
    setError("")
    const loggedInResponse = await fetch(`${baseURL}/accounts/authenticate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    const loggedIn = await loggedInResponse.json();

    if (loggedIn.status===200) {
      onSubmitProps.resetForm();
      dispatch(
        setLogin({
          user: loggedIn.data.user_details,
          token: loggedIn.data.token.token,
          landlord: loggedIn.data.landlord
        })
      );
      setIsLoading(false)
      navigate("/home");
    }else{
      setIsLoading(false)
      setError("Invalid Username or Password, Please try again")
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    if (isLogin) await login(values, onSubmitProps);
    if (isRegister) await register(values, onSubmitProps);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
      validationSchema={isLogin ? loginSchema : registerSchema}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box gap="30px" marginBottom={2}>
            {error && <Alert severity="error">{error}</Alert>}
          </Box>
          <Box
            display="grid"
            gap="20px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {isRegister ? (
              <>
                <TextField
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.first_name}
                  name="first_name"
                  error={
                    Boolean(touched.first_name) && Boolean(errors.first_name)
                  }
                  helperText={touched.first_name && errors.first_name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Last Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.last_name}
                  name="last_name"
                  error={Boolean(touched.last_name) && Boolean(errors.last_name)}
                  helperText={touched.last_name && errors.last_name}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone_number}
                  name="phone_number"
                  error={
                    Boolean(touched.phone_number) && Boolean(errors.phone_number)
                  }
                  helperText={touched.phone_number && errors.phone_number}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Email Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={Boolean(touched.email) && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  label="Business Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.business_name}
                  name="business_name"
                  error={Boolean(touched.business_name) && Boolean(errors.business_name)}
                  helperText={touched.business_name && errors.business_name}
                  sx={{ gridColumn: "span 4" }}
                />

                <TextField
                  label="Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  name="username"
                  error={
                    Boolean(touched.username) && Boolean(errors.username)
                  }
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 2" }}
                />
                {/* <Box
                  gridColumn="span 4"
                  border={`1px solid ${palette.neutral.medium}`}
                  borderRadius="5px"
                  p="1rem"
                >
                  <Dropzone
                    acceptedFiles=".jpg,.jpeg,.png"
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      setFieldValue("picture", acceptedFiles[0])
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Box
                        {...getRootProps()}
                        border={`2px dashed ${palette.primary.main}`}
                        p="1rem"
                        sx={{ "&:hover": { cursor: "pointer" } }}
                      >
                        <input {...getInputProps()} />
                        {!values.picture ? (
                          <p>Add Picture Here</p>
                        ) : (
                          <FlexBetween>
                            <Typography>{values.picture.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        )}
                      </Box>
                    )}
                  </Dropzone>
                </Box> */}
              </>
            ) : (
              <>
                <TextField
                  label="Username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="username"
                  error={Boolean(touched.username) && Boolean(errors.username)}
                  helperText={touched.username && errors.username}
                  sx={{ gridColumn: "span 4", borderRadius: "2rem" }}
                />
                <TextField
                  label="Password"
                  type="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  error={Boolean(touched.password) && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  sx={{ gridColumn: "span 4", borderRadius: "2rem" }}
                />
              </>
            )}
          </Box>

          {/* BUTTONS */}
          <Box>
            {isLoading ? (
              <LinearProgress
                color="warning"
                sx={{
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: "#FCB200",
                  borderRadius: "2rem",
                }}
              />
            ) : (
              <Button
                fullWidth
                type="submit"
                sx={{
                  m: "2rem 0",
                  p: "1rem",
                  borderRadius: "2rem",
                  fontSize: "16px",
                  fontWeight: 600,
                  backgroundColor: "#FCB200",
                  color: "#FFFFFF",
                  "&:hover": { backgroundColor: "#FCB200", color: "#89867f" },
                }}
              >
                {isLogin ? "LOGIN" : "REGISTER"}
              </Button>
            )}
            
            <Typography
              onClick={() => {
                setPageType(isLogin ? "register" : "login");
                setError("")
                resetForm();
              }}
              sx={{
                textDecoration: "underline",
                color: "#FCB200",
                "&:hover": {
                  cursor: "pointer",
                  color: "#FCB200",
                },
              }}
            >
              {isLogin
                ? "Don't have an account? Sign Up here."
                : "Already have an account? Login here."}
            </Typography>
            
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;
