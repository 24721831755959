import * as React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, Grid, InputLabel, Select, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function RentalsForm(props) {
  const { errors } = props;
  return (
  <Grid container component="form" noValidate autoComplete="off" spacing={2} sx={{marginTop:"1px"}}>
    <Grid item xs={12} sm={6}>
      <TextField size="small" fullWidth label="Property Name" onChange={props.handleRentalNameChange} error={Boolean(errors.rental_name)} helperText={errors.rental_name}/>
    </Grid>
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">Property Use Type</InputLabel>
        <Select label="Property Use Type" labelId="rental-type" id="rental-type-id" value={props.rentalType} onChange={props.handleChangeRentalType} error={Boolean(errors.rental_type)}>
          <MenuItem value="MIXED USE">Mixed Use</MenuItem>
          <MenuItem value="RESIDENTIAL">Residential</MenuItem>
          <MenuItem value="COMMERCIAL">Commercial</MenuItem>
        </Select>
        {errors.rental_type && <Typography color="maroon" variant="caption" >{errors.rental_type}</Typography>}
      </FormControl>
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
          label="Property Location (City)"
          labelId="rental-type"
          id="rental-type-id"
          fullWidth
          size="small"
          select
          value={props.rentalCity}
          onChange={props.handleRentalCityChange}
          error={Boolean(errors.location)} helperText={errors.location}
        >
          <MenuItem value="KAMPALA">Kampala</MenuItem>
        </TextField>
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField size="small" fullWidth label="Property Location (Parish & County)" onChange={props.handleRentalLocationChange} error={Boolean(errors.location_county)} helperText={errors.location_county}/>
    </Grid>
    <Grid item xs={12} sm={5} spacing={2}>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={props.security} onChange={props.handleSecurityCheck} />} label="Has 24 Hour Security?" />
        <FormControlLabel control={<Checkbox  checked={props.gym} onChange={props.handleGymCheck}/>} label="Has Gym?" />
        <FormControlLabel control={<Checkbox  checked={props.swimmingPool} onChange={props.handleSwimmingPoolCheck}/>} label="Has Swimming Pool?" />
      </FormGroup>
    </Grid>
    <Grid item xs={12} sm={7} spacing={2}>
      <FormGroup>
        <FormControlLabel control={<Checkbox  checked={props.elevator} onChange={props.handleElevatorCheck}/>} label="Has Elevator?" />
        <FormControlLabel control={<Checkbox  checked={props.hospital} onChange={props.handleHospitalCheck}/>} label="Hospital Access in less than 10 minutes" />
        <FormControlLabel control={<Checkbox  checked={props.mainRoad} onChange={props.handleMainRoadCheck}/>} label="Main Road Access in less than 5 minutes" />
      </FormGroup>
    </Grid>
  </Grid>
  );
}
