import * as React from "react";
import { Link, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { formatDate, formatDateString } from "utilities/date";
import ProgressScale from "components/ProgressScale";
import DummyTable from "components/Tables/DummyTable";
import FormDialog from "components/modal";
import BasicSelect from "components/select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TenantPaymentsTable from "components/Tables/TenantPaymentsTable";
import { useGetTenantOccupancyActivePeriodsData } from "services/hooks/rentals";
import PlainCard from "components/PlainCard";
import FlexBetween from "components/FlexBetween";
import { withoutSymbolFormatter } from "utilities/currencyFormatter";
import moment from "moment";


export default function TenantPayments(props) {
	const [rental, setRental] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [details, setDetails] = React.useState("");
	const [modal, setModal] = React.useState("");
	const occupancy = useSelector((state) => state.occupancy)
    const { activePeriods, isLoadingActivePeriods } = useGetTenantOccupancyActivePeriodsData();
    const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	const handleSelectChange = (event) => {
		setRental(event.target.value);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickPopOver = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingActivePeriods && <ProgressScale />}
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom:"20px"}}>
						<Typography variant="h3" className="pageHeader">Tenant Payments for {occupancy.related_tenant.first_name+' '+occupancy.related_tenant.last_name+' in Unit '+occupancy.related_rental_unit.unit_name+'-'+occupancy.related_rental_unit.related_rental.rental_name}</Typography>
					</div>
					
					<div>
						<FlexBetween>
							<PlainCard name="Days Left" value={activePeriods.days_left} />
							<PlainCard name="Unit Rent" value={activePeriods.unit_rent} />
							<PlainCard name="Current Outstanding Balance" value={activePeriods.money} />
							<PlainCard name="Total Complete Months" value={activePeriods.completed_months} />
						</FlexBetween>
					</div>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingActivePeriods ? (
								<DummyTable />
							) : (
								<TenantPaymentsTable
									rows={activePeriods.schedules}
									handleClickPopOver={handleClickPopOver}
									id={popperId}
									formatDate={formatDate}
									setDetails={setDetails}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}
