import React from 'react';
import { DialogContent, TextField, MenuItem, Select, InputLabel, FormControl, Autocomplete, Box } from '@mui/material';
import FlexBetween from 'components/FlexBetween';
import axios from 'axios';
import { baseURL } from 'services/API';

const EditExpenseForm = ({ newExpenses, handleFormChange, landlordProperties, errors, rentalTickets, expenseTypes }) => {

  const [selectedExpenseDetails, setSeletedExpenseDetails] = React.useState([]);
  const [error, setError] = React.useState(false)

  const fetchData = async (type) => {
    try {
      const response = await axios.get(
        `${baseURL}/admin/expense_details?type_id=${type}`
      );
      setSeletedExpenseDetails(response.data.data);
    } catch (e) {
      setError(true);
    }
    return true;
  };
  

  return (
    <DialogContent>
      <Autocomplete
        onChange={(e, value)=>handleFormChange({target:{ value: value.id, name:"related_landlord_rental" }})}
        label="Select Rental"
        fullWidth
        name="related_landlord_rental"
        options={landlordProperties}
        defaultValue={newExpenses.related_landlord_rental}
        getOptionLabel={(option) =>
          `${option.rental_name}`
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, landlordProperties) => (
          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props} key={landlordProperties.id}>
            {landlordProperties.rental_name}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{...params.inputProps,}}
            label="Select rental property"
            error={errors.related_landlord_rental}
            value={newExpenses.related_landlord_rental ? newExpenses.related_landlord_rental ?? null : ""}
          />
        )}
      />
      <FlexBetween sx={{ display:"flex", flexDirection:"row" }}>
        <TextField
          margin="dense"
          label="Expense Type"
          select
          sx={{ width:"49%" }}
          variant="outlined"
          name="expense_type"
          error={errors.expense_type}
          value={newExpenses.expense_type}
          onChange={(e, value)=> {
            handleFormChange(e);
            fetchData(e.target.value);
          }}
        >
          {expenseTypes.map((item, index)=>{
            return <MenuItem key={item.id} value={item.id}>{item.expense_type_name}</MenuItem>;
          })}
          
        </TextField>

        <TextField
          margin="dense"
          label="Expense"
          select
          sx={{ width:"49%" }}
          variant="outlined"
          name="related_expense_detail"
          error={errors.related_expense_detail}
          value={newExpenses.related_expense_detail}
          onChange={handleFormChange}
        >
          {selectedExpenseDetails.map((item, index)=>{
            return <MenuItem key={item.id} value={item.id}>{item.expense_detail}</MenuItem>;
          })}
        </TextField>
      </FlexBetween>
      

      <TextField
        margin="dense"
        label="Attach Ticket"
        select
        fullWidth
        variant="outlined"
        name="amount"
        error={errors.related_ticket}
        value={newExpenses.related_ticket}
        onChange={handleFormChange}
      >
        {rentalTickets.map((item) => {
          return <MenuItem value={item.id}>{item.ticket_name}</MenuItem>;
        })}
      </TextField>
      <TextField
        margin="dense"
        label="Amount"
        type="number"
        fullWidth
        variant="outlined"
        name="amount"
        error={errors.amount}
        value={newExpenses.amount}
        onChange={handleFormChange}
      />
      <TextField
        margin="dense"
        label="Description"
        type="text"
        fullWidth
        variant="outlined"
        name="description"
        error={errors.description}
        value={newExpenses.description}
        onChange={handleFormChange}
      />
    </DialogContent>
  );
};

export default EditExpenseForm;
