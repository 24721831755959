import { Box, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import * as React from 'react';

const NotFound = () => {

  return (
    <Box m="20px">
          <Grid container spacing={2}>
			 <Grid item xs={12}>
              <Box
               sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh"
               }}
              >
                <Box
                    sx={{
                        margin: "auto"
                    }}
                >
                    <Typography sx={{fontSize: 200}}>404</Typography>
                    <Typography sx={{fontSize: 20}}>Not Found</Typography>
                    <Typography sx={{fontSize: 20}}>You could be wrong, or it could be us</Typography>
                    <Typography sx={{fontSize: 20}}>Go back to the <Link to="/home">HOMEPAGE</Link> and start again</Typography>
                </Box>
              </Box>
           </Grid>
          </Grid>
    </Box>
  );
};

export default NotFound;