import * as React from "react";
import { Grid, Box, Paper, Button,Card, CardActions, CardContent, Typography, Divider  } from "@mui/material";
import DashboardPaymentsTable from "components/Tables/DashboardTables/DashboardPayments";
import TicketsTable from "components/Tables/TicketsTable";
import BasicCard from "components/Card";
import DummyCard from "components/DummyCard";
import DummyTable from "components/Tables/DummyTable";
import DoughnutChart from "components/Graphs/DoughnutChart";
import { useGetDashboardData } from "services/hooks/rentals";
import Skeleton from "@mui/material/Skeleton";
import { formatDate } from "utilities/date";
import { FaHouseChimneyUser } from "react-icons/fa6";
import { FaRegFolderOpen } from "react-icons/fa";
import { FaMoneyBills } from "react-icons/fa6";
import { MdPeopleAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FormDialog from "components/modal";
import RentalsForm from "components/Forms/RentalsForm";
import TopSnack from "components/TopSnack";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setRefreshCount } from "state";
import { useRentals } from "../../services/hooks/rentals";
import axios from "axios";
import { baseURL } from "../../services/API";
import * as yup from 'yup';
import BarChart from "components/Graphs/BarChart";
import { currencyFormatter } from "utilities/currencyFormatter";
import { MdBalance } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { BsCart4 } from "react-icons/bs";
import { usePropertyDashboardRevenue } from "services/hooks/rentals";
import { FlexColCenter } from "components/FlexBox/FlexBox";
import DashboardPropertyPaymentsTable from "components/Tables/DashboardTables/DashboardPropertyPayments";

export default function Dashboard() {
	const [open, setOpen] = React.useState(false);
	const { dashboardData, isLoadingDashboardData, errorLoadingDashboardData } = useGetDashboardData();
	const {propertyDashboardRevenue, isLoadingPropertyDashboardRevenue , errorPropertyDashboardRevenue} = usePropertyDashboardRevenue(4);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const landlordId = useSelector((state) => state.landlord);
	const { rentals, isLoadingRentals } = useRentals();
	const [rental, setRental] = React.useState("");
	const [modal, setModal] = React.useState("");
	const [details, setDetails] = React.useState("");
	const [rentalName, setRentalName] = React.useState("");
	const [rentalType, setRentalType] = React.useState("");
	const [rentalLocation, setRentalLocation] = React.useState("");
	const [rentalCity, setRentalCity] = React.useState("");
	const [security, setSecurity] = React.useState(false);
	const [activeRental, setActiveRental] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;
	const [openSnack, setOpenSnack] = React.useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [severity, setSeverity] = React.useState("");
	const [message, setMessage] = React.useState("");

	const revenueMonthsDataSet = propertyDashboardRevenue.months
    const revenueBalanceDataSet = propertyDashboardRevenue.balances
	const rentArrears = 2580000;
    const rentCollected =150000;

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

		const handleRentalNameChange = (event) => {
			const {value} = event.target
			setRentalName(value);
			if (!value.trim()) {
				setErrors((prev) => ({ ...prev, 'rental_name': `Property Name is required`}));
			} else {
				setErrors((prev) => ({ ...prev, "rental_name": "" }));
			}
		};

		const handleChangeRentalType = (event) => {
			const { value } = event.target;
			setRentalType(value);
			if (!value.trim()) {
				setErrors((prev) => ({ ...prev, 'rental_type': `Select the property type`}));
			} else {
				setErrors((prev) => ({ ...prev, "rental_type": "" }));
			}
		};

		const handleRentalLocationChange = (event) => {
			const { value } = event.target;
			setRentalLocation(value);
			if (!value.trim()) {
				setErrors((prev) => ({ ...prev, 'location': `Input a parish or county in the selected City`}));
			} else {
				setErrors((prev) => ({ ...prev, "location": "" }));
			}
		};

		const handleRentalCityChange = (event) => {
			setRentalCity(event.target.value);
		};

		const handleSecurityCheck = (event) => {
			setSecurity(event.target.checked);
		};

		const handleClickPopOver = (event, row) => {
			setAnchorEl(event.currentTarget);
			setActiveRental(row);
		};

		const handleClosePopOver = () => {
			setAnchorEl(null);
		};

		const handleCloseSnack = (event, reason) => {
			if (reason === "clickaway") {
				return;
			}
			setOpenSnack({ ...openSnack, open: false });
		};

		const handleModalView = (modalView) => {
			setModal(modalView);
		};

		const [errors, setErrors] = React.useState({});
		const propertySchema = yup.object().shape({
			rental_name: yup.string().required("Please enter the property name"),
			location: yup.string().required("Input a parish or county in the selected City"),
			rental_type: yup.string().required("Select the property type"),
		});

		const handleRentalFormSubmit = async () => {

			try {
				await propertySchema.validate({rental_name: rentalName, rental_type: rentalType, 	location: rentalLocation}, {abortEarly:false});
			} catch (error) {
				const newError = {}
				error.inner.forEach(err => {
					newError[err.path]=err.message
				})
				setErrors(newError)
				return
			}

			try {
				await axios.post(`${baseURL}/landlords/rentals`, {
					related_landlord: landlordId,
					rental_name: rentalName,
					rental_type: rentalType,
					location: rentalLocation,
				});
				// setSuccess(true);
				// setLoading(false);
				setOpenSnack({ ...openSnack, open: true });
				setSeverity("success");
				setMessage("Property Added Successfully");
				setOpen(false);
				dispatch(setRefreshCount());
			} catch (err) {
				setOpenSnack({ ...openSnack, open: true });
				setSeverity("error");
				setMessage("Property Addition failed, Please check property details");
			}
		};

	return (
		<Box m="20px">
			<Grid container>
				{isLoadingDashboardData ? (
					<Grid item xs={12}>
						<h1>Dashboard</h1>
						<Grid container spacing={4}>
							<Grid item xs={3}>
								<DummyCard />
							</Grid>
							<Grid item xs={3}>
								<DummyCard />
							</Grid>
							<Grid item xs={3}>
								<DummyCard />
							</Grid>
							<Grid item xs={3}>
								<DummyCard />
							</Grid>
						</Grid>
					</Grid>
				) : (
					<Grid item xs={12}>
						<Box sx={{ marginBottom: "15px", display: { xs: "block", sm: "flex", md: "flex" }, alignItems: "center", justifyContent: "space-between" }}>
							<h1>Dashboard</h1>
							<Button
								sx={{ marginBottom: { xs: "20px", sm: 0 } }}
								onClick={handleClickOpen}
								className="add-button"
								variant="contained"
								endIcon={<AddIcon />}
							>
								Add Property
							</Button>
						</Box>
						<Grid container spacing={3}>
							<Grid item xs={12} >
								<Grid container spacing={2}>
									<Grid item xs={2.3} >
										<BasicCard
											link="/properties"
											name={"PROPERTIES"}
											value={dashboardData.properties}
											color="#FCB200"
											bgcolor="#fff9eb"
											icon={<FaHouseChimneyUser />}
										/>
									</Grid>
									<Grid item xs={2.3} >
										<BasicCard link="/tenants" name={"TENANTS"} value={dashboardData.tenants} color="#A05ACE" bgcolor="#ffebff" icon={<MdPeopleAlt />} />
									</Grid>
									<Grid item xs={2.3} >
										<BasicCard link="/payments" name={"TOTAL MONTHLY COLLECTIONS"} value={currencyFormatter(parseFloat(dashboardData.total))} color="green" bgcolor="rgba(135, 211, 124)" icon={<FaMoneyBills />} />
									</Grid>
									<Grid item xs={2.3} >
										<BasicCard link="/tenants" name={"TOTAL ARREARS"} value={currencyFormatter(parseFloat(450000))} color="#FF5100" bgcolor="#ffebff" icon={<MdPeopleAlt />} />
									</Grid>
									<Grid item xs={2.3} >
										<BasicCard link="/ticketsTab" name={"OPEN TICKETS"} value={dashboardData.tickets} color="#6A8BE8" bgcolor="#ebefff" icon={<FaRegFolderOpen />} />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={1} sx={{ marginY: "0.5rem" }}>
                                <Grid item xs={12} >
                                    <Paper sx={{ padding:"1rem", width: "100%"}}>
                                        <Grid container xs={12}>
                                            <Grid item xs={12} sx={{ margin:"1rem" }}>
                                                <Typography variant="h6" fontWeight="bold">Revenue Overvue</Typography>
                                                <Typography variant="body2">Your finance report for all properties for year 2024</Typography>
                                                <Divider/>
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={8} sx={{ padding:"0.5rem" }} >
                                                    <BarChart
                                                        label = {revenueMonthsDataSet.map(x=>x.alias)}
                                                        data={[
                                                            {
                                                                label: "Income",
                                                                data: revenueMonthsDataSet.map(x=>x.income),
                                                                fill: false,
                                                                backgroundColor: "rgba(135, 211, 124)",
                                                                tension: 0.1,
                                                            },
                                                            {
                                                                label: "Expense",
                                                                data: revenueMonthsDataSet.map(x=>x.expense),
                                                                fill: false,
                                                                backgroundColor: "rgba(30, 139, 195)", 
                                                                tension: 0.1,
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                                <Divider variant="middle" orientation="vertical"/>
                                                <Grid item xs={3.7} sx={{ padding:"0.5rem"}}>
                                                    <FlexColCenter sx={{ display:"flex", flexDirection:"column", padding:"0.5rem",  }}>
                                                        <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                            <Typography sx={{ color: "#A05ACE", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "#ffebff"  }} variant="h2">
                                                                {<MdBalance/>}
                                                            </Typography>
                                                            <div>
                                                                <Typography variant="h5" fontWeight="bold">{currencyFormatter(parseFloat(revenueBalanceDataSet.total_balance))}</Typography>
                                                                <Typography variant="body2">Total Balance</Typography>
                                                            </div>
                                                        </Box>
                                                        <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                            <Typography sx={{ color: "green", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "rgba(135, 211, 124)" }} variant="h2">
                                                                {<GiCash/>}
                                                            </Typography>
                                                            <div>
                                                                <Typography variant="h5" fontWeight="bold">{currencyFormatter(parseFloat(revenueBalanceDataSet.total_income))}</Typography>
                                                                <Typography variant="body2">Total Income</Typography>
                                                            </div>
                                                        </Box>
                                                        <Box sx={{ margin:"1rem", display:"flex", justifyContent:"space-between"}}>
                                                            <Typography sx={{ color: "rgba(30, 139, 195)", padding: "5px 5px 0 5px", borderRadius: "12px", marginRight:"1px", backgroundColor: "lightblue"  }} variant="h2">
                                                                {<BsCart4/>}
                                                            </Typography>
                                                            <div>
                                                                <Typography variant="h5">{currencyFormatter(parseFloat(revenueBalanceDataSet.total_expense))}</Typography>
                                                                <Typography variant="body2">Total Expense</Typography>
                                                            </div>
                                                        </Box>
                                                    </FlexColCenter>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
						<Grid container spacing={3} sx={{ marginTop: "15px" }}>
							<Grid item xs={12} md={6}>
								<h3 style={{ margin: "0 0 8px 0" }}>Payments by Property</h3>
								<DashboardPropertyPaymentsTable rows={dashboardData.tenants_list} />
							</Grid>
							<Grid item xs={12} md={6}>
								<span
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<h3 style={{ margin: "0 0 8px 0" }}>Rent Due Tenants</h3>
									
								</span>
								<DashboardPaymentsTable rows={dashboardData.tenants_list} />
							</Grid>
						</Grid>
					</Grid>
				)}

				<FormDialog
					open={open}
					handleClose={handleClose}
					enableActions={true}
					content={
						<RentalsForm
							rentalType={rentalType}
							rentalCity={rentalCity}
							handleRentalNameChange={handleRentalNameChange}
							handleChangeRentalType={handleChangeRentalType}
							handleRentalLocationChange={handleRentalLocationChange}
							handleRentalCityChange={handleRentalCityChange}
							handleSecurityCheck={handleSecurityCheck}
							security={security}
							errors={errors}
						/>
					}
					title={"Add all Property Details"}
					handleSubmit={handleRentalFormSubmit}
					buttonText="submit"
				/>
				<TopSnack
					vertical={openSnack.vertical}
					horizontal={openSnack.horizontal}
					open={openSnack.open}
					handleCloseSnack={handleCloseSnack}
					message={message}
					severity={severity}
				/>
			</Grid>
		</Box>
	);
}
