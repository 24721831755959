import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";

const itemSchema = yup.object().shape({
  file_path: yup.string().required("required"),
});

const IdUploadForm = ({handleSubmit}) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const token = useSelector((state) => state.token);

  const initialValuesItem = {
    file_path: "",
  };


  const handleFormSubmit = async (values, onSubmitProps) => {

    /* Then create a new FormData obj */
    let formData = new FormData();

    /* append input field values to formData */
    for (let value in values) {
      formData.append(value, values[value]);
    }

    /* Can't console.log(formData), must
       use formData.entries() - example:  */
    for (let property of formData.entries()) {
      console.log(property[0], property[1]);
    }
  };

  return (
    <Formik
      initialValues={initialValuesItem}
      validationSchema={itemSchema}
    >
      {({
        values,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >

            <Box
              gridColumn="span 4"
              border={`1px solid ${palette.neutral.medium}`}
              borderRadius="5px"
              p="1rem"
            >
              <Dropzone
                acceptedFiles=".jpg,.jpeg,.png"
                multiple={false}
                onDrop={(acceptedFiles) =>
                  setFieldValue("file_path", acceptedFiles[0])
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!values.file_path ? (
                      <p>Upload Picture Here</p>
                    ) : (
                      <FlexBetween>
                        <Typography>{values.file_path.name}</Typography>
                        <EditOutlinedIcon />
                      </FlexBetween>
                    )}
                  </Box>
                )}
              </Dropzone>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default IdUploadForm;
