import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { baseURL } from "services/API";
import { Grid, Box, Typography } from "@mui/material";
import TenantsTable from "components/Tables/TenantsTable";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import BasicSelect from "components/select";
import FormDialog from "components/modal";
import TenantsForm from "components/Forms/TenantsForm";
import DummyTable from "components/Tables/DummyTable";
import ProgressScale from "components/ProgressScale";
import { useGetTenantsByRentalProperty, useRentals } from "services/hooks/rentals";
import { setRefreshCount, setOccupancyId } from "state";
import TopSnack from "components/TopSnack";
import TenantsPopover from "components/PopOvers/TenantsPopover";
import TenantMessageForm from "components/Forms/TenantMessageForm";
import TenantUpdateForm from "components/Forms/TenantUpdateForm";
import { useNavigate } from "react-router-dom";


export default function PropertyTenants({property_id}) {
	const token = useSelector((state) => state.token);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	const [rental, setRental] = React.useState("");
	const [unit, setUnit] = React.useState("");
	const [message, setMessage] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [openTenantDetails, setOpenTenantDetails] = React.useState(false);
	const [openSnack, setOpenSnack] = React.useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [severity, setSeverity] = React.useState("");
	const { tenantsByProperty, isLoadingTenantsByProperty } = useGetTenantsByRentalProperty(property_id);
	const { rentals, isLoadingRentals } = useRentals();
	const [isLoadedUnits, setIsLoadedUnits] = React.useState(false);
	const [units, setUnits] = React.useState([]);
	const [tenantDetails, setTenantDetails] = React.useState({});
	const [loadedTenant, setLoadedTenant] = React.useState(false);
	const [loadingTenantsError, setLoadingTenantsError] = React.useState("");
	const [isExistingTenant, setIsExisitingTenant] = React.useState(true);
	const [loadingTenantDetails, setLoadingTenantDetails] = React.useState(true);
	const [errorLoadingTenantDetails, setErrorLoadingTenantDetails] = React.useState(false);
	const [tenantNumber, setTenantNumber] = React.useState("");
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [modal, setModal] = React.useState("");
	const [details, setDetails] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;

	const handleSelectChange = async (event) => {
		setIsLoadedUnits(false);
		setRental(event.target.value);
		try {
			const response = await axios.get(`${baseURL}/landlords/rentals/units/unoccupied?rental_id=${event.target.value}`);
			setUnits(response.data.data);
			setIsLoadedUnits(true);
			return true;
		} catch (err) {
			return false;
		}
	};

	const handleChangeRentalUnit = (event) => {
		setUnit(event.target.value);
	};

	const handleChangeFirstName = (event) => {
		setFirstName(event.target.value);
	};

	const handleChangeLastName = (event) => {
		setLastName(event.target.value);
	};

	const handleClickOpen = () => {
		setLoadingTenantDetails(false);
		setLoadingTenantsError("");
		setOpen(true);
	};

	const handleClose = () => {
		setModal("");
		setOpen(false);
	};

	const handleClickOpenTenantDetails = () => {
		setOpenTenantDetails(true);
	};

	const handleCloseTenantDetails = () => {
		setOpenTenantDetails(false);
	};

	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnack({ ...openSnack, open: false });
	};

	const handleClickPopOver = (event, row) => {
		setAnchorEl(event.currentTarget);
		setDetails(row)
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const handleNavigateTenantPayments = (occupancy) => {
		dispatch(setOccupancyId(occupancy))
		navigate("/tenants/payments")
	}

	const handleChangeTenantNumber = async (e) => {
		setLoadedTenant(false);
		setIsExisitingTenant(true);
		setIsLoadedUnits(false);
		setLoadingTenantsError("");
		const tenant_number = e.target.value;
		if (tenant_number.length === 12) {
			setTenantNumber(tenant_number);
			try {
				const response = await axios.get(`${baseURL}/tenants?username=${tenant_number}`);
				setTenantDetails(response.data.data);
				setLoadedTenant(true);
				setLoadingTenantDetails(false);
				setLoadingTenantsError("");
				return true;
			} catch (err) {
				setIsExisitingTenant(false);
				setLoadingTenantDetails(false);
				setErrorLoadingTenantDetails(true);
				setLoadingTenantsError("");
				return false;
			}
		} else {
			setLoadingTenantsError("Please type a complete phone number with 12 digits including 256");
		}
	};

	const handleSubmitTenant = async () => {
		try {
			if (isExistingTenant) {
				await axios.post(`${baseURL}/landlords/rentals/units/assign`, {
					unit_id: unit,
					tenant_number: tenantDetails.tenant_number,
				});
			} else {
				const response = await axios.post(`${baseURL}/accounts/users`, {
					first_name: firstName,
					last_name: lastName,
					email: "dummy@email.com",
					phone_number: tenantNumber.slice(3),
					password: "@Rent123",
					username: `+${tenantNumber}`,
					to_activate: "True",
					unit_id: unit,
				});
				console.log(response);
			}
			setOpen(false);
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("success");
			setMessage("Tenant Added Successfully");
			dispatch(setRefreshCount())
		} catch (err) {
			setOpenSnack({ ...openSnack, open: true});
			setSeverity("error");
			setMessage("Tenant Addition failed, Please check data provided");
		}
	};

	const handleModalView = (modalView) => {
		setModal(modalView);
	};

	return (
		<Box m="20px">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{isLoadingTenantsByProperty && <ProgressScale />}
					<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<Typography variant="h4" fontWeight={"bold"}>Current Tenants</Typography>
					</Box>
					<Grid container spacing={2}>
						<Grid item sx={{ display: { xs: "none", sm: "block" } }} sm={7} md={4}>
							<BasicSelect
								value={rental}
								handleChange={handleSelectChange}
								label={"Filter Rental"}
								data={rentals}
								isLoading={isLoadingRentals}
								id={"main-tenants-rental-select"}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={8} sx={{ display: { xs: "none", sm: "flex" }, justifyContent: "end", alignItems: "center" }}>
							
						</Grid>
						<Grid item sx={{ display: { xs: "block", sm: "none" } }} xs={12} sm={4}>
							<BasicSelect
								value={rental}
								handleChange={handleSelectChange}
								label={"Filter Rental"}
								data={rentals}
								isLoading={isLoadingRentals}
								id={"main-tenants-rental-select"}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 3 }}>
							{isLoadingTenantsByProperty ? (
								<DummyTable />
							) : (
								<TenantsTable
									setDetails={setDetails}
									rows={tenantsByProperty}
									isLoadingRentalTenants={isLoadingTenantsByProperty}
									handleClickPopOver={handleClickPopOver}
									handleNavigateTenantPayments={handleNavigateTenantPayments}
									id={popperId}
								/>
							)}
						</Grid>
					</Grid>
					<TenantsPopover
						popperId={popperId}
						openPopper={openPopper}
						anchorEl={anchorEl}
						handleClickOpen={handleClickOpen}
						handleClosePopOver={handleClosePopOver}
						handleClickOpenTenantDetails={handleClickOpenTenantDetails}
						handleNavigateTenantPayments={handleNavigateTenantPayments}
						details={details}
						handleModalView={handleModalView}
						navigate={navigate}
					/>

					{modal === "edit" ? (
						<FormDialog
							title="Edit Tenant"
							enableActions={true}
							content={<TenantUpdateForm details={details} />}
							open={open}
							handleClose={handleClose}
							buttonText="update"
						/>
					) : modal === "message" ? (
						<FormDialog
							title={`Sending Message to ${details.related_tenant.first_name}`}
							enableActions={true}
							content={<TenantMessageForm />}
							open={open}
							handleClose={handleClose}
							buttonText="submit"
						/>
					) : modal === "delete" ? (
						<FormDialog
							title="End Tenancy"
							enableActions={true}
							open={open}
							content="Are you sure you want to end this Tenancy?"
							handleClose={handleClose}
							buttonText="Yes, End Tenancy"
						/>
					): null}

					<TopSnack
						vertical={openSnack.vertical}
						horizontal={openSnack.horizontal}
						open={openSnack.open}
						handleCloseSnack={handleCloseSnack}
						message={message}
						severity={severity}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
