import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: "My First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
};

 const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // No gridlines on the x-axis
        },
      },
      y: {
        grid: {
          display: false, // No gridlines on the y-axis
        },
      },
    },
    plugins: {
      title: {
        display: false,  // Disable the title
      },
    }, 
  };

export default function BarChart(props) {
  return (
    <Bar 
      data={{
        labels: props.label,
        datasets: props.data,
      }}
      options={options}
    />
  );
}
